import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Paper,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Button,
    TextField,
    Modal,
    FormControlLabel,
    Checkbox,
    Divider,
} from "@mui/material";
import { useDrag, useDrop } from "react-dnd";
import { images } from "../../constants/tierListWeapons"; // Weapon list
import { ImageItem } from "../../types/weaponItem"; // ImageItem type
import { ammoIcons } from "../../constants/ammoIcons"; // Ammo icons map
import { useParams } from "react-router-dom";
import { saveTierList } from "../../api/saveTierList";
import { useAuth } from "AuthContext";
import { COLORS } from "constants/colors";

const TierListMaker: React.FC = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [tierList, setTierList] = useState<{ [key: string]: ImageItem[] }>({
        S: [],
        A: [],
        B: [],
        C: [],
    });
    const [fetchedName, setFetchedName] = useState<string | null>(null);
    const [tierListName, setTierListName] = useState<string>("");
    const [fetchedDescription, setFetchedDescription] = useState<string>("");
    const [createdBy, setCreatedBy] = useState<string>("");
    const [createdAt, setCreatedAt] = useState<string>("");

    const { isAuthenticated } = useAuth();

    // Form
    const [description, setDescription] = useState<string>("");
    const [showUsername, setShowUsername] = useState<boolean>(false);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTierListName(event.target.value);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    // Fetch the tier list by ID when the component loads
    useEffect(() => {
        const fetchTierList = async () => {
            if (!id) return;

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/tier-list/${id}`
                );
                if (response.ok) {
                    const data = await response.json();

                    // Convert backend data (IDs) to frontend data (ImageItem[])
                    const tierListFromBackend: { [key: string]: ImageItem[] } =
                        {};
                    for (const tier in data.tierList) {
                        tierListFromBackend[tier] = data.tierList[tier]
                            .map((id: number) =>
                                images.find((item) => item.id === id)
                            )
                            .filter(
                                (item: ImageItem): item is ImageItem =>
                                    item !== undefined
                            );
                    }

                    setTierList(tierListFromBackend);
                    setFetchedName(data.name || "");
                    setFetchedDescription(data.description || ""); // Set description
                    setCreatedBy(data.createdBy || ""); // Set createdBy
                    setCreatedAt(data.createdAt || ""); // Set createdAt
                } else {
                    console.error("Failed to fetch tier list");
                }
            } catch (error) {
                console.error("Error fetching tier list:", error);
            }
        };

        fetchTierList();
    }, [id]);

    const [selectedWeaponType, setSelectedWeaponType] = useState<string>("All");

    const handleWeaponTypeChange = (event: SelectChangeEvent<string>) => {
        setSelectedWeaponType(event.target.value);
    };

    const getImagesInTiers = (): Set<number> => {
        const idsInTiers = new Set<number>();
        Object.values(tierList).forEach((items) => {
            items.forEach((item) => idsInTiers.add(item.id));
        });
        return idsInTiers;
    };

    const filteredImages = images.filter((image) => {
        const idsInTiers = getImagesInTiers();
        const matchesType =
            selectedWeaponType === "All" ||
            image.weaponType === selectedWeaponType;
        return matchesType && !idsInTiers.has(image.id);
    });

    const [isPublic, setIsPublic] = useState(false);

    const handleSaveTierList = async () => {
        // Check if the name is empty
        if (tierListName.trim().length < 3) {
            alert("Tier list name must be at least 3 characters long!");
            return;
        }

        // Check if the tier list has any selections
        const hasSelections = Object.values(tierList).some(
            (items) => items.length > 0
        );
        if (!hasSelections) {
            alert("You must select at least one item in the tier list!");
            return;
        }
        await saveTierList(
            tierList,
            tierListName,
            isPublic,
            description,
            showUsername
        );
        handleCloseModal();
    };

    const moveImageToTier = (image: ImageItem, tier: string | null) => {
        setTierList((prev) => {
            const newTierList = { ...prev };

            // Remove the image from any previous tier
            for (const key in newTierList) {
                newTierList[key] = newTierList[key].filter(
                    (item) => item.id !== image.id
                );
            }

            // If tier is null, the item goes back to the bottom list
            if (tier) {
                newTierList[tier] = [...newTierList[tier], image];
            }

            return newTierList;
        });
    };

    return (
        <Box sx={{ padding: "20px", textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
                Tier List Maker
            </Typography>
            {id && (
                <Paper
                    elevation={3}
                    sx={{
                        padding: "20px",
                        margin: "0 auto",
                        maxWidth: "800px",
                        textAlign: "left",
                    }}
                >
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontWeight: "bold", color: "primary.main" }}
                    >
                        {fetchedName}
                    </Typography>

                    <Divider sx={{ marginBottom: 2 }} />

                    {fetchedDescription && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Description:
                            </Typography>
                            <Typography
                                variant="body1"
                                sx={{ marginBottom: 3, whiteSpace: "pre-line" }}
                            >
                                {fetchedDescription}
                            </Typography>
                        </>
                    )}

                    {/* Created Info */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            marginTop: 2,
                            padding: "10px",

                            borderRadius: "8px",
                        }}
                    >
                        <Box>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Created By:
                            </Typography>
                            <Typography variant="body1">
                                {createdBy || "Anonymous"}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                            >
                                Created On:
                            </Typography>
                            <Typography variant="body1">{createdAt}</Typography>
                        </Box>
                    </Box>
                </Paper>
            )}

            <Typography
                marginBottom={3}
                marginTop={1}
                variant="body1"
                gutterBottom
            >
                Drag and drop images into the tiers below or back to the list.
            </Typography>

            {/* Dropdown for Weapon Type Selection */}
            <FormControl sx={{ marginBottom: "20px", minWidth: "200px" }}>
                <InputLabel id="weapon-type-label">Weapon Type</InputLabel>
                <Select
                    labelId="weapon-type-label"
                    value={selectedWeaponType}
                    onChange={handleWeaponTypeChange}
                >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="AR">Assault Rifle (AR)</MenuItem>
                    <MenuItem value="SR">Sniper Rifle (SR)</MenuItem>
                    <MenuItem value="DMR">
                        Designated Marksman Rifle (DMR)
                    </MenuItem>
                    <MenuItem value="SMG">Submachine Gun (SMG)</MenuItem>
                    <MenuItem value="LMG">Light Machine Gun (LMG)</MenuItem>
                </Select>
            </FormControl>

            {/* Vertical Tier Rows */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                }}
            >
                {["S", "A", "B", "C"].map((tier) => (
                    <TierRow
                        key={tier}
                        tier={tier}
                        items={tierList[tier]}
                        onDrop={(item) => moveImageToTier(item, tier)}
                    />
                ))}
            </Box>

            {/* Images to Drag */}
            <Box
                sx={{
                    marginTop: "20px",
                    border: "1px dashed gray",
                    padding: "10px",
                    minHeight: "150px",
                    textAlign: "center",
                }}
                ref={
                    useDrop({
                        accept: "IMAGE",
                        drop: (item: ImageItem) => moveImageToTier(item, null),
                    })[1]
                } // Use drop ref for the weapons list
            >
                <Typography variant="h6">
                    Drag weapons here to remove them from tiers
                </Typography>
                {/* Button to Share Tier List */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenModal}
                    sx={{
                        backgroundColor: COLORS.orange,
                        fontWeight: "bold",
                        ":hover": {
                            backgroundColor: COLORS.darkOrange,
                        },
                    }}
                >
                    Share Tier List
                </Button>
                <Box
                    display="flex"
                    justifyContent="center"
                    gap={2}
                    flexWrap="wrap"
                    mt={2}
                >
                    {filteredImages.map((image) => (
                        <DraggableImage key={image.id} image={image} />
                    ))}
                </Box>
            </Box>

            {/* Modal for Tier List Name */}
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        backgroundColor: "#2e2e2e",
                        padding: "20px",
                        borderRadius: "8px",
                        boxShadow: 24,
                        textAlign: "center",
                        width: "400px", // Optional: Set width to avoid dynamic resizing
                    }}
                >
                    <Typography id="modal-title" variant="h6" gutterBottom>
                        Save Your Tier List
                    </Typography>
                    <TextField
                        id="tier-list-name"
                        label="Tier List Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={tierListName}
                        onChange={handleNameChange}
                        sx={{ marginBottom: "20px" }}
                    />
                    <TextField
                        id="tier-list-description"
                        label="Description (optional)"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        sx={{
                            marginBottom: "10px",
                            input: { color: "white" },
                            label: { color: "white" },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: "white",
                                },
                                "&:hover fieldset": {
                                    borderColor: COLORS.orange,
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: COLORS.orange,
                                },
                            },
                        }}
                    />
                    {isAuthenticated && (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isPublic}
                                        onChange={(e) =>
                                            setIsPublic(e.target.checked)
                                        }
                                        sx={{
                                            color: "white",
                                            "&.Mui-checked": {
                                                color: COLORS.orange,
                                            },
                                        }}
                                    />
                                }
                                label="Make this tier list public"
                                sx={{
                                    alignItems: "left",
                                    justifyContent: "left",
                                    textAlign: "left",
                                }}
                            />
                            {isPublic && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={showUsername}
                                            onChange={(e) =>
                                                setShowUsername(
                                                    e.target.checked
                                                )
                                            }
                                            sx={{
                                                color: "white",
                                                "&.Mui-checked": {
                                                    color: COLORS.orange,
                                                },
                                            }}
                                        />
                                    }
                                    label="Show my username on the public list browser"
                                    sx={{
                                        marginBottom: "20px", // Add space between the checkbox and the button
                                        alignItems: "center",
                                        justifyContent: "center",
                                        color: "white",
                                    }}
                                />
                            )}
                        </>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveTierList}
                        sx={{ marginTop: 1, textTransform: "none" }}
                    >
                        Save and Share
                    </Button>
                    {!isAuthenticated && (
                        <>
                            <Typography marginTop={1}>
                                You are not logged in, so you can only share the
                                tier list link with your friends.
                            </Typography>
                            <Typography marginTop={1}>
                                If you want to publish a tier list to the public
                                list browser, you need to log in.
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default TierListMaker;

// Tier Row Component
const TierRow: React.FC<{
    tier: string;
    items: ImageItem[];
    onDrop: (item: ImageItem) => void;
}> = ({ tier, items, onDrop }) => {
    const [, dropRef] = useDrop({
        accept: "IMAGE",
        drop: (item: ImageItem) => onDrop(item),
    });

    return (
        <Paper
            ref={dropRef}
            sx={{
                width: "100%",
                maxWidth: "600px",
                padding: "10px",
                minHeight: "120px",
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <Typography variant="h6">{tier} Tier</Typography>
            <Box display="flex" gap={1} flexWrap="wrap" mt={1}>
                {items.map((item) => (
                    <DraggableImage key={item.id} image={item} />
                ))}
            </Box>
        </Paper>
    );
};

// Draggable Image Component
const DraggableImage: React.FC<{ image: ImageItem }> = ({ image }) => {
    const [, dragRef] = useDrag({
        type: "IMAGE",
        item: image,
    });

    return (
        <Box
            ref={dragRef}
            sx={{
                textAlign: "center",
                position: "relative",
            }}
        >
            <Typography variant="subtitle2">
                {image.title} ({image.weaponType})
            </Typography>
            <Box
                component="img"
                src={image.src}
                alt={image.title}
                sx={{
                    width: "100px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    boxShadow: 3,
                }}
            />
            {image.crateWeapon && (
                <Box
                    component="img"
                    src="https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Icons/CarePackage/CarePackage_Normal.png"
                    alt="Crate Icon"
                    sx={{
                        position: "absolute",
                        bottom: "5px",
                        left: "5px",
                        width: "24px",
                        height: "24px",
                    }}
                />
            )}
            {image.ammoType && ammoIcons[image.ammoType] && (
                <Box
                    component="img"
                    src={ammoIcons[image.ammoType]}
                    alt={`${image.ammoType} Ammo Icon`}
                    sx={{
                        position: "absolute",
                        top: "25px",
                        right: "5px",
                        width: "24px",
                        height: "24px",
                    }}
                />
            )}
        </Box>
    );
};

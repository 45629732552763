import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Navbar } from "./components/Navbar";
import { Home } from "./pages/Home";
import { AccountSettings } from "./pages/usersOnly/AccountSettings";
import { Tech } from "./pages/Tech";
import { Register } from "./pages/usersOnly/Register";
import { Login } from "./pages/usersOnly/Login";
import { AuthProvider } from "./AuthContext";
import { MapsLanding } from "./pages/maps/MapsLanding";
import { TipsStrategies } from "./pages/TipsStrategies";
import TierListMaker from "./pages/tierLists/TierListMaker";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Patches from "./pages/Patches";
import { PublicTierLists } from "./pages/tierLists/PublicTierLists";
import ProtectedRoute from "./pages/ProtectedRoute";
import { TierListsLanding } from "./pages/tierLists/TierListsLanding";
import { ErangelSecretRooms } from "./pages/maps/ErangelSecretRooms";
import { TaegoSecretRooms } from "./pages/maps/TaegoSecretRooms";
import { DestonSecretRooms } from "./pages/maps/DestonSecretRooms";
import { FourKMaps } from "./pages/maps/FourKMaps";
import IBRCrates from "pages/maps/IBRRouter";
import { FAQ } from "pages/FAQ";
import { Contact } from "pages/Contact";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import PlayerStatsLandingPage from "pages/playerStats/PlayerStatsLanding";
import PlayerSearch from "pages/playerStats/PlayerSearch";
import Footer from "components/Footer";
import Resources from "pages/Resources";
import ReactGA from "react-ga4";
import PrivacyPolicy from "pages/PrivacyPolicy";
import SupportUsPage from "pages/SupportUsPage";
import SavedPlayers from "pages/usersOnly/SavedPlayers";
import SavedMatches from "pages/usersOnly/SavedMatches";
import { ForgotPassword } from "pages/usersOnly/ForgotPassword";
import { ResetPassword } from "pages/usersOnly/ResetPassword";
import { Verify } from "pages/usersOnly/Verify";
import { TouchBackend } from "react-dnd-touch-backend";
import { useMediaQuery, useTheme } from "@mui/material";
import Preferences from "pages/usersOnly/Preferences";
import VersionHistory from "pages/VersionHistory";
import { COLORS } from "constants/colors";

const darkTheme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#222222", // Soft dark gray for the main background
            paper: "#2A2A2A", // Slightly lighter gray for cards/containers
        },
        text: {
            primary: COLORS.white, // White text for readability
            secondary: COLORS.silver, // Light gray for secondary text
        },
    },
    typography: {
        fontFamily: "'Arial', sans-serif",
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-root": {
                        backgroundColor: "#2A2A2A",
                        color: COLORS.white,
                        borderRadius: "4px",
                        "& fieldset": {
                            borderColor: "#555555",
                        },
                        "&:hover fieldset": {
                            borderColor: "#888888",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#FF9800",
                        },
                    },
                    "& .MuiInputLabel-root": {
                        color: COLORS.silver,
                    },
                    "& .MuiFormHelperText-root": {
                        color: COLORS.silver,
                    },
                },
            },
        },
    },
});

const App: React.FC = () => {
    useEffect(() => {
        ReactGA.initialize("G-3J8DQ0W56C"); // Your Measurement ID
        ReactGA.send("pageview"); // Track the initial page load
    }, []);

    if (process.env.REACT_APP_API_URL != "http://localhost:5000") {
        console.log = () => {}; // Disable console.log
        console.warn = () => {}; // Optionally disable console.warn
        console.error = () => {}; // Optionally disable console.error
    }

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <AuthProvider>
                <Router>
                    {/* Main layout container */}
                    <div
                        className="App"
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            minHeight: "100vh",
                        }}
                    >
                        <Navbar />
                        {/* Content wrapper with flex-grow to fill space */}
                        <div style={{ flex: 1 }}>
                            <Routes>
                                <Route path="/" element={<Home />} />
                                {/* Login / Registration / Account Routes */}
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/account-settings"
                                    element={
                                        <ProtectedRoute>
                                            <AccountSettings />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/preferences"
                                    element={
                                        <ProtectedRoute>
                                            <Preferences />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/saved-players"
                                    element={
                                        <ProtectedRoute>
                                            <SavedPlayers />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/saved-matches"
                                    element={
                                        <ProtectedRoute>
                                            <SavedMatches />
                                        </ProtectedRoute>
                                    }
                                />

                                {/* Tech Stack */}
                                <Route path="/tech" element={<Tech />} />
                                <Route
                                    path="/showTechStack"
                                    element={<Tech />}
                                />
                                {/* ---------------------------- */}
                                {/* FAQ / Feedback / Register Routes */}
                                <Route path="/faq" element={<FAQ />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route
                                    path="/privacy-policy"
                                    element={<PrivacyPolicy />}
                                />
                                <Route
                                    path="/version-history"
                                    element={<VersionHistory />}
                                />
                                <Route
                                    path="/register"
                                    element={<Register />}
                                />
                                <Route path="/verify" element={<Verify />} />
                                <Route
                                    path="/forgot-password"
                                    element={<ForgotPassword />}
                                />
                                <Route
                                    path="/reset-password"
                                    element={<ResetPassword />}
                                />
                                {/* ---------------------------- */}
                                {/* Player Stats Routes */}
                                <Route
                                    path="/player-search"
                                    element={<PlayerSearch />}
                                />
                                <Route
                                    path="/player-stats/*"
                                    element={<PlayerStatsLandingPage />}
                                />
                                {/* ---------------------------- */}
                                {/* Tier List Routes */}
                                <Route
                                    path="/tier-lists"
                                    element={<TierListsLanding />}
                                />

                                <Route
                                    path="/tier-lists/maker"
                                    element={
                                        <DndProvider
                                            backend={
                                                isMobile
                                                    ? TouchBackend
                                                    : HTML5Backend
                                            }
                                        >
                                            <TierListMaker />
                                        </DndProvider>
                                    }
                                />
                                <Route
                                    path="/tier-lists/public"
                                    element={<PublicTierLists />}
                                />
                                <Route
                                    path="/tier-lists/maker/:id"
                                    element={
                                        <DndProvider
                                            backend={
                                                isMobile
                                                    ? TouchBackend
                                                    : HTML5Backend
                                            }
                                        >
                                            <TierListMaker />
                                        </DndProvider>
                                    }
                                />
                                {/* ---------------------------- */}

                                {/* Maps Routes */}
                                <Route path="/maps" element={<MapsLanding />} />
                                <Route
                                    path="/maps/4k-maps"
                                    element={<FourKMaps />}
                                />
                                <Route
                                    path="/maps/4k-maps/:mapName"
                                    element={<FourKMaps />}
                                />
                                <Route
                                    path="/maps/erangel-secret-rooms"
                                    element={<ErangelSecretRooms />}
                                />
                                <Route
                                    path="/maps/taego-secret-rooms"
                                    element={<TaegoSecretRooms />}
                                />
                                <Route
                                    path="/maps/deston-secret-rooms"
                                    element={<DestonSecretRooms />}
                                />
                                {/* IBR Routes */}
                                <Route
                                    path="/maps/ibr-crates/*"
                                    element={<IBRCrates />}
                                />
                                {/* ---------------------------- */}
                                <Route
                                    path="/tips-strategies"
                                    element={<TipsStrategies />}
                                />
                                <Route path="/patches" element={<Patches />} />
                                <Route
                                    path="/resources"
                                    element={<Resources />}
                                />
                                <Route
                                    path="/support-us"
                                    element={<SupportUsPage />}
                                />
                            </Routes>
                        </div>
                        <Footer />
                    </div>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;

import React from "react";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography,
    useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";

const PrivacyPolicy: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: { xs: "16px", md: "40px" }, // Responsive padding
                backgroundColor: theme.palette.background.default, // Use the theme's default background
                color: theme.palette.text.primary, // Use the theme's text color
                minHeight: "80vh",
                maxWidth: "1400px", // Limit the maximum width of the content
                margin: "0 auto", // Center the content horizontally
                borderRadius: "8px", // Add rounded corners for better aesthetics
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ textAlign: "left" }}>
                Privacy Policy
            </Typography>

            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                At PUBG Meta, your privacy is important to us. This page
                outlines how we collect, use, and protect your data while using
                our service.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ textAlign: "left" }}>
                What Information We Collect
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                1. <strong>Google Analytics:</strong>
                <br />
                We use Google Analytics to monitor website traffic and
                understand how users interact with our site. This helps us
                improve the user experience.
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                2. <strong>Cookies:</strong>
                <br />
                Our website uses cookies to enhance your browsing experience.
                These cookies remember your preferences and track anonymized
                usage data for analytics.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ textAlign: "left" }}>
                How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                The data we collect is used to:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText primary="Improve the website's functionality and user experience" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Analyze trends and performance" />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Debug issues and maintain website security" />
                </ListItem>
            </List>

            <Typography variant="h5" gutterBottom sx={{ textAlign: "left" }}>
                Your Privacy Choices
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                - You can opt out of Google Analytics tracking by using the{" "}
                <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: theme.palette.primary.main,
                        textDecoration: "none",
                    }}
                >
                    Google Analytics Opt-out Browser Add-on
                </a>
                .
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                - Most browsers allow you to disable cookies through your
                settings. Note that disabling cookies may affect your user
                experience.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ textAlign: "left" }}>
                Data Security
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                We use commercially reasonable measures to protect your data.
                However, no method of transmission over the internet is 100%
                secure.
            </Typography>

            <Typography variant="h5" gutterBottom sx={{ textAlign: "left" }}>
                Contact Us
            </Typography>
            <Typography variant="body1" paragraph sx={{ textAlign: "left" }}>
                If you have any questions or concerns about this Privacy Policy,
                please use the
                <Link
                    to="/contact"
                    style={{
                        textDecoration: "none",
                        color: theme.palette.primary.main,
                        marginLeft: "4px",
                    }}
                >
                    Contact
                </Link>{" "}
                option in the top left menu.
            </Typography>
        </Box>
    );
};

export default PrivacyPolicy;

import React, { useEffect, useState } from "react";
import { Box, Typography, Link, Paper, Button } from "@mui/material";
import { patches } from "../constants/patches";
import ReactGA from "react-ga4";
import { COLORS } from "constants/colors";

export const Patches: React.FC = () => {
    const [expandedPatch, setExpandedPatch] = useState<number | null>(null);

    const toggleSummary = (index: number) => {
        setExpandedPatch(expandedPatch === index ? null : index);
    };

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/patches",
            title: "Patch History Page",
        });
    }, []);

    return (
        <Box sx={{ padding: "20px", textAlign: "center" }}>
            <Typography variant="h5" gutterBottom>
                PUBG Patch History
            </Typography>
            <Typography variant="body1" gutterBottom>
                Explore the history of PUBG patches with their details and
                summaries.
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px",
                    marginTop: "20px",
                }}
            >
                {patches.map((patch, index) => (
                    <Paper
                        key={index}
                        elevation={3}
                        sx={{
                            padding: "10px 20px",
                            width: "100%",
                            maxWidth: "600px",
                            textAlign: "left",
                            borderRadius: "8px",
                        }}
                    >
                        <Typography variant="h6">{patch.name}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {patch.date}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: "10px",
                                marginTop: "10px",
                            }}
                        >
                            {patch.aiSummary && (
                                <Button
                                    variant="outlined"
                                    onClick={() => toggleSummary(index)}
                                    sx={{
                                        textTransform: "none", // Prevent uppercase text
                                        display: "flex", // Align items horizontally
                                        alignItems: "center", // Center items vertically
                                        color: COLORS.orange, // Text color
                                        borderColor: COLORS.orange, // Outline color
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(255, 165, 0, 0.1)", // Light orange background on hover
                                            borderColor: COLORS.darkOrange, // Darker orange on hover
                                        },
                                    }}
                                >
                                    {expandedPatch === index
                                        ? "↑ Hide AI Summary ↑"
                                        : "↓ View AI Summary ↓"}
                                </Button>
                            )}
                            <Button
                                href={patch.link}
                                target="_blank"
                                rel="noopener"
                                sx={{
                                    textTransform: "none", // Prevent uppercase text
                                    display: "flex", // Align items horizontally
                                    alignItems: "center", // Center items vertically
                                    color: COLORS.orange, // Text color
                                    borderColor: COLORS.orange, // Outline color
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(255, 165, 0, 0.1)", // Light orange background on hover
                                        borderColor: COLORS.darkOrange, // Darker orange on hover
                                    },
                                }}
                                variant="outlined"
                            >
                                View Online
                            </Button>
                            {patch.pdf && (
                                <Button
                                    href={patch.pdf}
                                    target="_blank"
                                    rel="noopener"
                                    sx={{
                                        textTransform: "none", // Prevent uppercase text
                                        display: "flex", // Align items horizontally
                                        alignItems: "center", // Center items vertically
                                        color: COLORS.orange, // Text color
                                        borderColor: COLORS.orange, // Outline color
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(255, 165, 0, 0.1)", // Light orange background on hover
                                            borderColor: COLORS.darkOrange, // Darker orange on hover
                                        },
                                    }}
                                    variant="outlined"
                                >
                                    View Patch Notes PDF
                                </Button>
                            )}
                        </Box>
                        {expandedPatch === index && patch.aiSummary && (
                            <Typography
                                variant="body2"
                                sx={{
                                    marginTop: "10px",
                                    whiteSpace: "pre-line",
                                    fontStyle: "italic",
                                }}
                            >
                                {patch.aiSummary}
                            </Typography>
                        )}
                    </Paper>
                ))}
            </Box>
        </Box>
    );
};

export default Patches;

export const formatGameMode = (
    gameMode: string,
    matchType?: string
): string => {
    if (matchType === "training" || matchType === "trainingroom") {
        return "Training";
    }

    if (gameMode === "tdm") {
        return "Team Deathmatch";
    }
    if (gameMode === "ibr") {
        return "Intense Battle Royale";
    }

    return gameMode
        .split("-")
        .map((word, index, arr) => {
            if (
                (word === "fpp" || word === "tpp") &&
                index === arr.length - 1
            ) {
                return word.toUpperCase(); // Capitalize FPP/TPP if at the end
            }
            return word.charAt(0).toUpperCase() + word.slice(1); // Capitalize the first letter of other words
        })
        .join(" ");
};

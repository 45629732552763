export const SECTIONS = [
    {
        title: "General Tips",
        content: `
1. Turn on the Korean Rating Steam launch option:
   - Turns blood green for better visibility.
   - Makes dead bodies disappear instantly to avoid confusion.
   - Molotov flames appear as gas (slightly harder to spot).
2. Increase brightness on darker maps for better visibility.
3. Place your assault weapon in slot #2 for faster access and improved stealth when prone.
4. Use C4 on a vehicle to create a mobile bomb. Honk to draw attention and time detonation carefully.
5. Delete game videos from the following directory for faster loading into the game:

\`\`\`
steamapps\\common\\PUBG\\TslGame\\Content\\Movies
\`\`\`
        `,
    },
    {
        title: "Vehicle Tips",
        content: `
1. Hold "W" while switching seats to keep moving forward.
2. Swap seats right before exiting the vehicle for an unexpected approach.
3. When getting in vehicles, always keep a slight distance from vehicles to avoid hitbox damage.
4. Lead shots slightly ahead when shooting at moving vehicles.
        `,
    },
    {
        title: "Smoke Tips",
        content: `
1. Switch to FPP in smoke to see nearby enemies.
2. Smoke when reviving, but be cautious as it may attract grenades.
3. Crouch in smoke if enemies are at a higher elevation, otherwise they can see you from above.
4. TPP: Peek around the edges of smoke while staying hidden.
        `,
    },
    {
        title: "Grenade Tips",
        content: `
1. Jump while throwing grenades to increase distance.
2. Grenades will only bounce up to 3 times.
        `,
    },
    {
        title: "Revive Tips",
        content: `
1. Move around the player reviving you for a better position post-revive.
2. Use smoke to conceal revives when possible.
        `,
    },
    {
        title: "TPP Tips",
        content: `
1. TPP: Peek around the edges of smoke while staying hidden.
        `,
    },
    {
        title: "Strategy",
        content: `
1. Close the gap when engaging enemies to leave them without cover.
2. Pre-fire around corners where you expect enemies.
3. Aim for headshots for faster kills with AR/SMGs.
4. In 1v4 situations, consider repositioning over finishing a knocked enemy.
5. Stay close to teammates when pushing a single enemy for a better trade.
        `,
    },
    {
        title: "Essential Settings",
        content: `
1. **Graphics** - Set Display Mode to Borderless for easy multitasking.
2. Set Lobby FPS Cap to 60 to prevent GPU overheating.
3. Set In-Game FPS Cap to Display Based.
4. Disable Universal Brightness for map-specific brightness adjustments.
5. Set Render Scale to 100% to match monitor resolution.
6. Enable HRTF for better sound quality.
        `,
    },
    {
        title: "Essential Key Bindings",
        content: `
1. **Jump Only** - Set to Space.
2. **Vault Only** - Set to "V".
3. **Toggle Perspective** - Map to a mouse button.
4. **Instant Volume Reducer** - Map to a convenient button.
        `,
    },
    {
        title: "Recommended Gaming Peripherals",
        content: `
1. Wireless mouse with multiple buttons, e.g., Razer Naga.
2. Cloth mouse pad for better control.
3. Mouse cable holder or wireless mouse to avoid cable interference.
4. Good headset for precise sound localization.
        `,
    },
];

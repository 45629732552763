export const nonEsportsMaps = [
    {
        name: "Deston",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Deston_Main_High_Res.png",
        size: 8,
    },
    {
        name: "Rondo",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Rondo_Main_High_Res.png",
        size: 8,
    },
    {
        name: "Sanhok",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Sanhok_Main_High_Res.png",
        size: 4,
    },
];

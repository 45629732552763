export const smallMaps = [
    {
        name: "Camp Jackal",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Camp_Jackal_Main_High_Res.png",
        size: 2,
    },
    {
        name: "Paramo",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Paramo_Main_High_Res.png",
        size: 3,
    },
    {
        name: "Karakin",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Karakin_Main_High_Res.png",
        size: 2,
    },
    {
        name: "Haven",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Haven_Main_High_Res.png",
        size: 1,
    },
];

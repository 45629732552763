import { Box, Typography } from "@mui/material";
import { COLORS } from "constants/colors";
import { motion } from "framer-motion";

export const MapSection: React.FC<{
    title: string;
    maps: { name: string; url: string; size: number }[];
    onMapSelect: (map: { name: string; url: string; size: number }) => void;
}> = ({ title, maps, onMapSelect }) => (
    <>
        <Typography variant="h6" sx={{ marginTop: "20px" }}>
            {title}
        </Typography>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "20px",
                marginTop: "20px",
            }}
        >
            {maps.map((map) => {
                // Generate the low-resolution URL from the high-resolution one
                const lowResUrl = map.url
                    .replace(
                        "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/",
                        "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Maps/"
                    )
                    .replace("High_Res", "Low_Res");

                return (
                    <motion.div whileHover={{ scale: 1.1 }} key={map.name}>
                        <Box
                            onClick={() => onMapSelect(map)}
                            sx={{
                                width: "300px",
                                height: "300px",
                                border: "3px solid orange",
                                borderRadius: "10px",
                                overflow: "hidden",
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-end",
                                position: "relative",
                                backgroundColor: "#fff",
                                "&:hover": {
                                    boxShadow: "0 4px 15px rgba(0,0,0,0.2)",
                                },
                            }}
                        >
                            {/* Use the low-resolution URL for the thumbnail */}
                            <Box
                                component="img"
                                src={lowResUrl}
                                alt={map.name}
                                sx={{
                                    width: "100%",
                                    height: "calc(100% - 30px)",
                                    objectFit: "cover",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                }}
                            />
                            <Box
                                sx={{
                                    backgroundColor: COLORS.orange,
                                    color: "#fff",
                                    padding: "5px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    position: "relative",
                                    zIndex: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    paddingInline: "10px",
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {map.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {`${map.size}x${map.size} km`}
                                </Typography>
                            </Box>
                        </Box>
                    </motion.div>
                );
            })}
        </Box>
    </>
);

import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

const SavedMatches: React.FC = () => {
    const [savedMatches, setSavedMatches] = useState<
        {
            matchId: string;
            platform: string;
            playerName: string;
            savedAt: Date;
        }[]
    >([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSavedMatches = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem("authToken");
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/saved-matches`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch saved matches");
                }

                const data = await response.json();
                setSavedMatches(data.matches);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchSavedMatches();
    }, []);

    const handleMatchClick = (
        matchId: string,
        platform: string,
        playerName: string
    ) => {
        navigate(
            `/player-stats/matches/${matchId}?platform=${platform}&name=${encodeURIComponent(
                playerName
            )}`
        );
    };

    return (
        <Box sx={{ padding: 2, maxWidth: 1400, margin: "0 auto" }}>
            <Typography variant="h5" gutterBottom>
                Saved Matches
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : savedMatches.length > 0 ? (
                <List>
                    {savedMatches.map((match, index) => (
                        <ListItem
                            key={index}
                            onClick={() =>
                                handleMatchClick(
                                    match.matchId,
                                    match.platform,
                                    match.playerName
                                )
                            }
                            sx={{
                                cursor: "pointer",
                            }}
                        >
                            <ListItemText
                                primary={`Match ID: ${match.matchId}`}
                                secondary={`Platform: ${
                                    match.platform
                                } | Saved since: ${format(
                                    new Date(match.savedAt),
                                    "PPpp"
                                )}`}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography>No saved matches found.</Typography>
            )}
        </Box>
    );
};

export default SavedMatches;

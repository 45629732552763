import { loadFromCache, saveToCache } from "./cacheUtils";

export const fetchAccountId = async (
    playerName: string,
    platform: string
): Promise<any> => {
    const cacheKey = `accountId_${platform}_${playerName}`;

    // Check cache first
    const cachedData = loadFromCache(cacheKey);
    if (cachedData) {
        console.log(`Using cached account ID for ${playerName} on ${platform}`);
        return cachedData;
    }

    // Fetch account details if not in cache
    console.log(`Fetching new account ID for ${playerName} on ${platform}`);
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
        );
        if (!response.ok) {
            throw new Error(
                `Failed to fetch account ID: ${response.statusText}`
            );
        }

        const accountData = await response.json();

        // Save account data to cache
        saveToCache(cacheKey, accountData);

        return accountData;
    } catch (error) {
        console.error(
            `Error fetching account ID for ${playerName} on ${platform}:`,
            error
        );
        throw error;
    }
};

import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import mernStackImage from "assets/techStack/mern.png";
import ReactGA from "react-ga4";
import useFeatureToggle from "hooks/useFeatureToggle";

export const Tech: React.FC = () => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/tech",
            title: "Tech Stack Page",
        });
    }, []);

    const showTechStack = useFeatureToggle();

    if (!showTechStack) {
        return null; // Hide the page if the feature is disabled
    }

    return (
        <Box
            sx={{
                padding: "40px",
                textAlign: "center",
                minHeight: "80vh",
            }}
        >
            {/* Title */}
            <Typography variant="h4" gutterBottom sx={{ color: "#00796B" }}>
                PUBG Meta Tech Stack
            </Typography>
            <Typography
                variant="body1"
                gutterBottom
                sx={{ marginBottom: "40px" }}
            >
                Welcome to PUBG Meta! Here's an overview of the technologies
                used to build it.
            </Typography>

            <Divider sx={{ marginBottom: "40px" }} />

            <Grid container spacing={4}>
                {/* Left Column */}
                <Grid item xs={12} md={6}>
                    {/* Tech Stack - MERN */}
                    <Card
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            marginBottom: "20px",
                        }}
                    >
                        <CardContent>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{ color: "#00796B" }}
                            >
                                Tech Stack - MERN
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                gutterBottom
                            >
                                <strong>Frontend:</strong> Built using React
                                (TypeScript) with Material-UI for styling and
                                React DnD for drag-and-drop interactions.
                                <br />
                                <strong>Backend:</strong> Powered by Express.js
                                and Node.js, with MongoDB as the database.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* MERN Stack Image */}
                    <Box
                        component="img"
                        src={mernStackImage}
                        alt="MERN Stack"
                        sx={{
                            maxWidth: "100%",
                            margin: "0 auto",
                            display: "block",
                            borderRadius: "8px",
                        }}
                    />

                    {/* Deployment and Domain */}
                    <Card
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <CardContent>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{ color: "#00796B" }}
                            >
                                Deployment and Domain
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                gutterBottom
                            >
                                <strong>Hosting:</strong> The application is
                                deployed on <strong>Render.com</strong>, a
                                modern platform as a service (PaaS) for hosting
                                both frontend and backend applications
                                seamlessly.
                                <br />
                                <strong>Domain:</strong> The custom domain is
                                managed through <strong>Namecheap</strong>, a
                                reliable and affordable domain registrar.
                            </Typography>
                        </CardContent>
                    </Card>

                    {/* Database */}
                    <Card
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                        }}
                    >
                        <CardContent>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{ color: "#00796B" }}
                            >
                                Database
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                gutterBottom
                            >
                                <strong>MongoDB:</strong> A NoSQL database used
                                for storing user-generated tier lists and
                                authentication data.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={6}>
                    <Card
                        sx={{
                            boxShadow: 2,
                            borderRadius: 2,
                        }}
                    >
                        <CardContent>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{ color: "#00796B" }}
                            >
                                Key Libraries and Tools
                            </Typography>
                            <Typography
                                variant="body1"
                                align="left"
                                gutterBottom
                            >
                                <strong>Frontend:</strong>
                                <ul>
                                    <li>
                                        @mui/material and @emotion: For a modern
                                        UI.
                                    </li>
                                    <li>axios: For making HTTP requests.</li>
                                    <li>
                                        framer-motion: For animations and smooth
                                        interactions.
                                    </li>
                                </ul>
                                <strong>Backend:</strong>
                                <ul>
                                    <li>
                                        bcryptjs: For secure password hashing.
                                    </li>
                                    <li>
                                        jsonwebtoken: For authentication and
                                        session management.
                                    </li>
                                    <li>
                                        nodemailer: For sending transactional
                                        emails.
                                    </li>
                                    <li>
                                        dotenv: For managing environment
                                        variables securely.
                                    </li>
                                    <li>
                                        express-rate-limit: To protect the
                                        backend by limiting excessive requests
                                        from users or bots.
                                    </li>
                                </ul>
                                <strong>Development Tools:</strong>
                                <ul>
                                    <li>
                                        nodemon: For hot-reloading the backend.
                                    </li>
                                    <li>
                                        ts-node: For running TypeScript directly
                                        in development.
                                    </li>
                                    <li>
                                        typescript: Used across the frontend and
                                        backend for type safety.
                                    </li>
                                </ul>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

// src/components/IBRLocationDetails.tsx
import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { IBRMapData } from "constants/IBRMapData";

const IBRLocationDetails: React.FC = () => {
    const { mapName, locationName } = useParams<{
        mapName: string;
        locationName: string;
    }>();

    // Validate map and location
    const map = mapName ? IBRMapData[mapName] : undefined;
    const location = map?.locations.find(
        (loc) => loc.name.toLowerCase() === locationName
    );

    if (!map) {
        return <Typography variant="h5">Map not found</Typography>;
    }

    if (!location) {
        return <Typography variant="h5">Location not found</Typography>;
    }

    return (
        <Box sx={{ padding: "20px", textAlign: "center" }}>
            <Typography variant="h4" gutterBottom>
                {location.name} Crates
            </Typography>

            <Box
                component="img"
                src={location.boxesImage}
                alt={`${location.name} Crates`}
                sx={{
                    width: { xs: "100%", sm: "80%", md: "60%" },
                    borderRadius: "8px",
                    marginBottom: "20px",
                }}
            />
        </Box>
    );
};

export default IBRLocationDetails;

import farmImage from "assets/maps/ibr/erangel/areas/farm.jpg";
import georgopolImage from "assets/maps/ibr/erangel/areas/georgopol.jpg";
import hospitalImage from "assets/maps/ibr/erangel/areas/hospital.jpg";
import mansionImage from "assets/maps/ibr/erangel/areas/mansion.jpg";
import severnyImage from "assets/maps/ibr/erangel/areas/severny.jpg";
import farmBoxesImage from "assets/maps/ibr/erangel/boxes/farm.jpg";
import georgopolBoxesImage from "assets/maps/ibr/erangel/boxes/georgopol.jpg";
import hospitalBoxesImage from "assets/maps/ibr/erangel/boxes/hospital.jpg";
import mansionBoxesImage from "assets/maps/ibr/erangel/boxes/mansion.jpg";
import severnyBoxesImage from "assets/maps/ibr/erangel/boxes/severny.jpg";

import elAzaharImage from "assets/maps/ibr/miramar/areas/el-azahar.jpg";
import losLeonesImage from "assets/maps/ibr/miramar/areas/los-leones.jpg";
import monteNuevoImage from "assets/maps/ibr/miramar/areas/monte-nuevo.jpg";
import resortImage from "assets/maps/ibr/miramar/areas/resort.jpg";
import waterTreatmentImage from "assets/maps/ibr/miramar/areas/water-treatment.jpg";
import elAzaharBoxesImage from "assets/maps/ibr/miramar/boxes/el-azahar.jpg";
import losLeonesBoxesImage from "assets/maps/ibr/miramar/boxes/los-leones.jpg";
import monteNuevoBoxesImage from "assets/maps/ibr/miramar/boxes/monte-nuevo.jpg";
import resortBoxesImage from "assets/maps/ibr/miramar/boxes/resort.jpg";
import waterTreatmentBoxesImage from "assets/maps/ibr/miramar/boxes/water-treatment.jpg";

import bootcampImage from "assets/maps/ibr/sanhok/areas/bootcamp.jpg";
import paradiseResortImage from "assets/maps/ibr/sanhok/areas/paradise-resort.jpg";
import ruinsImage from "assets/maps/ibr/sanhok/areas/ruins.jpg";
import bootcampBoxesImage from "assets/maps/ibr/sanhok/boxes/bootcamp.jpg";
import paradiseResortBoxesImage from "assets/maps/ibr/sanhok/boxes/paradise-resort.jpg";
import ruinsBoxesImage from "assets/maps/ibr/sanhok/boxes/ruins.jpg";

import coalMineImage from "assets/maps/ibr/vikendi/areas/coal-mine.jpg";
import lumberYardImage from "assets/maps/ibr/vikendi/areas/lumber-yard.jpg";
import observatoryImage from "assets/maps/ibr/vikendi/areas/observatory.jpg";
import villaImage from "assets/maps/ibr/vikendi/areas/villa.jpg";
import wineryImage from "assets/maps/ibr/vikendi/areas/winery.jpg";
import coalMineBoxesImage from "assets/maps/ibr/vikendi/boxes/coal-mine.jpg";
import lumberYardBoxesImage from "assets/maps/ibr/vikendi/boxes/lumber-yard.jpg";
import observatoryBoxesImage from "assets/maps/ibr/vikendi/boxes/observatory.jpg";
import villaBoxesImage from "assets/maps/ibr/vikendi/boxes/villa.jpg";
import wineryBoxesImage from "assets/maps/ibr/vikendi/boxes/winery.jpg";

import airportImage from "assets/maps/ibr/taego/areas/airport.jpg";
import fishingCampImage from "assets/maps/ibr/taego/areas/fishing-camp.jpg";
import goDokImage from "assets/maps/ibr/taego/areas/go-dok.jpg";
import songAmImage from "assets/maps/ibr/taego/areas/song-am.jpg";
import wolSongImage from "assets/maps/ibr/taego/areas/wol-song.jpg";
import airportBoxesImage from "assets/maps/ibr/taego/boxes/airport.jpg";
import fishingCampBoxesImage from "assets/maps/ibr/taego/boxes/fishing-camp.jpg";
import goDokBoxesImage from "assets/maps/ibr/taego/boxes/go-dok.jpg";
import songAmBoxesImage from "assets/maps/ibr/taego/boxes/song-am.jpg";
import wolSongBoxesImage from "assets/maps/ibr/taego/boxes/wol-song.jpg";

import barcliftImage from "assets/maps/ibr/deston/areas/barclift.jpg";
import holstonMeadowsImage from "assets/maps/ibr/deston/areas/holston-meadows.jpg";
import hydroelectricDamImage from "assets/maps/ibr/deston/areas/hydroelectric-dam.jpg";
import lodgeImage from "assets/maps/ibr/deston/areas/lodge.jpg";
import swampImage from "assets/maps/ibr/deston/areas/swamp.jpg";
import barcliftBoxesImage from "assets/maps/ibr/deston/boxes/barclift.jpg";
import holstonMeadowsBoxesImage from "assets/maps/ibr/deston/boxes/holston-meadows.jpg";
import hydroelectricDamBoxesImage from "assets/maps/ibr/deston/boxes/hydroelectric-dam.jpg";
import lodgeBoxesImage from "assets/maps/ibr/deston/boxes/lodge.jpg";
import swampBoxesImage from "assets/maps/ibr/deston/boxes/swamp.jpg";

export interface IBRLocation {
    name: string;
    image: string;
    boxesImage: string;
}

export interface IBRMap {
    name: string;
    locations: IBRLocation[];
}

export const IBRMapData: Record<string, IBRMap> = {
    erangel: {
        name: "Erangel",
        locations: [
            { name: "Farm", image: farmImage, boxesImage: farmBoxesImage },
            {
                name: "Georgopol",
                image: georgopolImage,
                boxesImage: georgopolBoxesImage,
            },
            {
                name: "Hospital",
                image: hospitalImage,
                boxesImage: hospitalBoxesImage,
            },
            {
                name: "Mansion",
                image: mansionImage,
                boxesImage: mansionBoxesImage,
            },
            {
                name: "Severny",
                image: severnyImage,
                boxesImage: severnyBoxesImage,
            },
        ],
    },
    miramar: {
        name: "Miramar",
        locations: [
            {
                name: "El Azahar",
                image: elAzaharImage,
                boxesImage: elAzaharBoxesImage,
            },
            {
                name: "Los Leones",
                image: losLeonesImage,
                boxesImage: losLeonesBoxesImage,
            },
            {
                name: "Monte Nuevo",
                image: monteNuevoImage,
                boxesImage: monteNuevoBoxesImage,
            },
            {
                name: "Resort",
                image: resortImage,
                boxesImage: resortBoxesImage,
            },
            {
                name: "Water Treatment",
                image: waterTreatmentImage,
                boxesImage: waterTreatmentBoxesImage,
            },
        ],
    },
    sanhok: {
        name: "Sanhok",
        locations: [
            {
                name: "Bootcamp",
                image: bootcampImage,
                boxesImage: bootcampBoxesImage,
            },
            {
                name: "Paradise Resort",
                image: paradiseResortImage,
                boxesImage: paradiseResortBoxesImage,
            },
            { name: "Ruins", image: ruinsImage, boxesImage: ruinsBoxesImage },
        ],
    },
    vikendi: {
        name: "Vikendi",
        locations: [
            {
                name: "Coal Mine",
                image: coalMineImage,
                boxesImage: coalMineBoxesImage,
            },
            {
                name: "Lumber Yard",
                image: lumberYardImage,
                boxesImage: lumberYardBoxesImage,
            },
            {
                name: "Observatory",
                image: observatoryImage,
                boxesImage: observatoryBoxesImage,
            },
            { name: "Villa", image: villaImage, boxesImage: villaBoxesImage },
            {
                name: "Winery",
                image: wineryImage,
                boxesImage: wineryBoxesImage,
            },
        ],
    },
    taego: {
        name: "Taego",
        locations: [
            {
                name: "Airport",
                image: airportImage,
                boxesImage: airportBoxesImage,
            },
            {
                name: "Fishing Camp",
                image: fishingCampImage,
                boxesImage: fishingCampBoxesImage,
            },
            { name: "Go Dok", image: goDokImage, boxesImage: goDokBoxesImage },
            {
                name: "Song Am",
                image: songAmImage,
                boxesImage: songAmBoxesImage,
            },
            {
                name: "Wol Song",
                image: wolSongImage,
                boxesImage: wolSongBoxesImage,
            },
        ],
    },
    deston: {
        name: "Deston",
        locations: [
            {
                name: "Barclift",
                image: barcliftImage,
                boxesImage: barcliftBoxesImage,
            },
            {
                name: "Holston Meadows",
                image: holstonMeadowsImage,
                boxesImage: holstonMeadowsBoxesImage,
            },
            {
                name: "Hydroelectric Dam",
                image: hydroelectricDamImage,
                boxesImage: hydroelectricDamBoxesImage,
            },
            { name: "Lodge", image: lodgeImage, boxesImage: lodgeBoxesImage },
            { name: "Swamp", image: swampImage, boxesImage: swampBoxesImage },
        ],
    },
    rondo: {
        name: "Rondo",
        locations: [
            {
                name: "Map 1 Placeholder",
                image: farmImage,
                boxesImage: farmBoxesImage,
            },
            {
                name: "Map 2 Placeholder",
                image: farmImage,
                boxesImage: farmBoxesImage,
            },
        ],
    },
};

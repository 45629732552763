import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    TextField,
    MenuItem,
    Button,
    Alert,
    CircularProgress,
} from "@mui/material";
import ReactGA from "react-ga4";

export const Contact: React.FC = () => {
    const [feedbackType, setFeedbackType] = useState("Bug");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/feedback",
            title: "Feedback Page",
        });
    }, []);

    const feedbackOptions = [
        "Bug",
        "Feature Suggestion",
        "Privacy",
        "Collaboration",
        "Other",
    ];

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        setError("");
        setSuccess(false);

        try {
            const response = await fetch(`${apiUrl}/api/submit-feedback`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    type: feedbackType,
                    message,
                }),
            });

            if (response.ok) {
                setSuccess(true);
                setMessage("");
            } else {
                const errorData = await response.json();
                setError(errorData.message || "Failed to send feedback.");
            }
        } catch (err) {
            setError("Something went wrong. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                padding: "40px",
                textAlign: "left",
                maxWidth: "1400px",
                margin: "0 auto",
            }}
        >
            <Typography variant="h4" gutterBottom sx={{ color: "#00796B" }}>
                Contact
            </Typography>
            <Typography variant="body1" gutterBottom>
                We’d love to hear from you! Whether you want to report a bug,
                suggest a feature, inquire about privacy, collaborate with us,
                or share anything else, feel free to reach out. Select one of
                the options below and let us know how we can help!
            </Typography>
            <Box
                component="form"
                onSubmit={handleSubmit}
                sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                <TextField
                    select
                    label="Feedback Type"
                    value={feedbackType}
                    onChange={(e) => setFeedbackType(e.target.value)}
                >
                    {feedbackOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label="Message"
                    multiline
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={loading || !message.trim()}
                >
                    {loading ? <CircularProgress size={24} /> : "Submit"}
                </Button>
                {success && (
                    <Alert severity="success">
                        Feedback submitted. Thank you!
                    </Alert>
                )}
                {error && <Alert severity="error">{error}</Alert>}
            </Box>
        </Box>
    );
};

import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    IconButton,
    Tooltip,
    DialogActions,
    Dialog,
    Button,
    MenuItem,
    Select,
    DialogTitle,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { getPlatformLogo } from "./lifetimeStats/LifetimeStats";

import WeaponMasterySection, {
    WeaponCategories,
} from "components/WeaponMasterySection";
import PlayerTag from "components/PlayerTag";
import { mapNameMapping } from "utils/mapNameMapping";
import ReactGA from "react-ga4";
import { useAuth } from "AuthContext";

// Heart Icons for Save Players
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { COLORS } from "constants/colors";

interface Clip {
    matchId: string;
    attackId: number;
    videoId: string;
    killer: string;
    victim: string;
    map: string;
    mode: string;
    date: string;
    url: string;
}

interface ClanInfo {
    clanName: string;
    clanTag: string;
    clanLevel: number;
    clanMemberCount: number;
}

interface SurvivalMastery {
    tier: number;
    level: number;
    xp: number;
}

const PlayerProfile: React.FC = () => {
    // Search parameters
    const [searchParams] = useSearchParams();
    const playerName = searchParams.get("name") || "Unknown Player";
    const platform = searchParams.get("platform") || "steam";
    const [playerCategory, setPlayerCategory] = useState<string | null>(null);

    // Fetched data states
    const [accountId, setAccountId] = useState<string | null>(null);
    const [clanId, setClanId] = useState<string | null>(null);
    const [banType, setBanType] = useState<
        "PermanentBan" | "TemporaryBan" | "Innocent" | null
    >(null);

    // PUBG Report Clip States
    const [clips, setClips] = useState<Clip[]>([]);
    const [killerClips, setKillerClips] = useState<Clip[]>([]);
    const [victimClips, setVictimClips] = useState<Clip[]>([]);

    // Clan Info State
    const [clanInfo, setClanInfo] = useState<ClanInfo | null>(null);

    // Mastery states
    const [survivalMastery, setSurvivalMastery] =
        useState<SurvivalMastery | null>(null);
    const [weaponMastery, setWeaponMastery] = useState<WeaponCategories | null>(
        null
    );

    // Loading and error states
    const [accountLoading, setAccountLoading] = useState(false);
    const [accountError, setAccountError] = useState<string | null>(null);

    const [clipsLoading, setClipsLoading] = useState(false);
    const [clipsError, setClipsError] = useState<string | null>(null);

    const [clanLoading, setClanLoading] = useState(false);
    const [clanError, setClanError] = useState<string | null>(null);

    const [survivalLoading, setSurvivalLoading] = useState(false);
    const [survivalError, setSurvivalError] = useState<string | null>(null);

    const [weaponLoading, setWeaponLoading] = useState(false);
    const [weaponError, setWeaponError] = useState<string | null>(null);

    // Show or hide PUBG Report Tables
    const [showTables, setShowTables] = useState(false);

    // Pagination states for PUBG Report tables
    const [killerPage, setKillerPage] = useState(0);
    const [killerRowsPerPage, setKillerRowsPerPage] = useState(10);
    const [victimPage, setVictimPage] = useState(0);
    const [victimRowsPerPage, setVictimRowsPerPage] = useState(10);

    // Login only
    const { isAuthenticated } = useAuth();
    const [isSaved, setIsSaved] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState("Following");
    const [open, setOpen] = useState(false);

    const SurvivalMasteryXP: React.FC<{ xp: number }> = ({ xp }) => {
        const MAX_XP = 4318570; // Maximum XP
        const percentage = ((xp / MAX_XP) * 100).toFixed(2);

        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1, // Space between text and icon
                }}
            >
                <Typography>XP: {Number(xp).toLocaleString()}</Typography>
                <Tooltip
                    title={`Progress: ${percentage}% of ${MAX_XP.toLocaleString()} (Tier 5 Level 500 XP)`}
                    arrow
                >
                    <InfoOutlinedIcon
                        sx={{
                            fontSize: "16px",
                            verticalAlign: "middle",
                            color: "#888",
                            cursor: "pointer",
                        }}
                    />
                </Tooltip>
            </Box>
        );
    };

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/profile",
            title: "Player Profile Page",
        });
    }, []);

    useEffect(() => {
        const checkIfSaved = async () => {
            try {
                const token = localStorage.getItem("authToken");
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/saved-players`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch saved players");
                }

                const data = await response.json();
                console.log("Saved players data:", data);

                let categoryFound: string | null = null;
                let playerFound = false;

                // Iterate through each category
                for (const [category, players] of Object.entries(data)) {
                    if (!Array.isArray(players)) continue;

                    const found = players.some(
                        (player: { playerName: string; platform: string }) =>
                            player.playerName === playerName &&
                            player.platform === platform
                    );

                    if (found) {
                        categoryFound = category;
                        playerFound = true;
                        break; // Stop searching once the player is found
                    }
                }

                setPlayerCategory(categoryFound); // Set the found category or null
                setIsSaved(playerFound); // Update saved state
            } catch (error) {
                console.error("Error checking saved player:", error);
            }
        };

        if (isAuthenticated) {
            checkIfSaved();
        }
    }, [isAuthenticated, playerName, platform]);

    const handleToggleSave = async (category?: string) => {
        try {
            const token = localStorage.getItem("authToken");
            if (!token) {
                alert("You are not logged in!");
                return;
            }

            console.log(category);

            if (isSaved) {
                // Remove player from saved list
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/remove-player`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            playerName,
                            platform,
                            category: playerCategory,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to remove player");
                }

                setIsSaved(false);
            } else if (!category) {
                // Open the dialog to select a category
                setOpen(true);
            } else {
                // Save player with the selected category
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/save-player`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            playerName,
                            platform,
                            category,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to save player");
                }

                setIsSaved(true);
                setPlayerCategory(category);
            }
        } catch (error) {
            console.error("Error toggling save:", error);
            alert("An error occurred while toggling the saved state.");
        }
    };

    useEffect(() => {
        const loadAccountData = async () => {
            try {
                const cacheKey = `${platform}-${playerName}`;
                console.log(`[DEBUG] Checking cache for key: ${cacheKey}`);
                const cachedData = localStorage.getItem(cacheKey);

                if (cachedData) {
                    console.log(
                        `[DEBUG] Cache hit for account data: ${cacheKey}`
                    );
                    const accountData = JSON.parse(cachedData);
                    setAccountId(accountData.accountId);
                    setClanId(accountData.clanId || null);
                    setBanType(accountData.banType || null);
                } else {
                    console.log(
                        "[DEBUG] Cache miss. Fetching account data from API."
                    );
                    setAccountLoading(true);
                    setAccountError(null);

                    const accountResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
                    );

                    if (!accountResponse.ok) {
                        throw new Error("Failed to fetch account data");
                    }

                    const accountData = await accountResponse.json();
                    console.log("[DEBUG] Account data fetched:", accountData);

                    localStorage.setItem(cacheKey, JSON.stringify(accountData));
                    setAccountId(accountData.accountId);
                    setClanId(accountData.clanId || null);
                    setBanType(accountData.banType || null);
                }
            } catch (err) {
                console.error("[DEBUG] Error fetching account data:", err);
                setAccountError((err as Error).message);
            } finally {
                setAccountLoading(false);
                console.log("[DEBUG] Account data loading complete.");
            }
        };

        if (platform && playerName) {
            console.log("[DEBUG] Starting loadAccountData...");
            loadAccountData();
        }
    }, [platform, playerName]);

    useEffect(() => {
        if (!accountId) {
            console.log(
                "[DEBUG] accountId is null, skipping loadAdditionalData."
            );
            return;
        }

        const loadAdditionalData = async () => {
            try {
                // Fetch Clips
                const clipsCacheKey = `clips-${accountId}`;
                console.log(
                    `[DEBUG] Checking cache for clips: ${clipsCacheKey}`
                );
                const cachedClips = localStorage.getItem(clipsCacheKey);

                if (cachedClips) {
                    console.log("[DEBUG] Cache hit for clips.");
                    const clipsData: Clip[] = JSON.parse(cachedClips);
                    setClips(clipsData);
                    setKillerClips(
                        clipsData.filter(
                            (clip) =>
                                clip.killer.toLowerCase() ===
                                playerName.toLowerCase()
                        )
                    );
                    setVictimClips(
                        clipsData.filter(
                            (clip) =>
                                clip.victim.toLowerCase() ===
                                playerName.toLowerCase()
                        )
                    );
                } else {
                    console.log("[DEBUG] Cache miss. Fetching clips from API.");
                    setClipsLoading(true);
                    setClipsError(null);

                    const clipsResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/pubg-clips/${accountId}`
                    );

                    if (!clipsResponse.ok) {
                        throw new Error("Failed to fetch clips");
                    }

                    const clipsData: Clip[] = await clipsResponse.json();
                    console.log("[DEBUG] Clips data fetched:", clipsData);

                    localStorage.setItem(
                        clipsCacheKey,
                        JSON.stringify(clipsData)
                    );
                    setClips(clipsData);
                    setKillerClips(
                        clipsData.filter(
                            (clip) =>
                                clip.killer.toLowerCase() ===
                                playerName.toLowerCase()
                        )
                    );
                    setVictimClips(
                        clipsData.filter(
                            (clip) =>
                                clip.victim.toLowerCase() ===
                                playerName.toLowerCase()
                        )
                    );
                }
            } catch (err) {
                console.error("[DEBUG] Error fetching clips:", err);
                setClipsError((err as Error).message);
            } finally {
                setClipsLoading(false);
            }

            try {
                // Define the cache key
                const survivalCacheKey = `survival-mastery-${platform}-${accountId}`;
                console.log(
                    `[DEBUG] Checking cache for key: ${survivalCacheKey}`
                );

                // Check if data is in cache
                const cachedSurvival = localStorage.getItem(survivalCacheKey);
                if (cachedSurvival) {
                    console.log("[DEBUG] Cache hit for survival mastery.");
                    setSurvivalMastery(JSON.parse(cachedSurvival));
                } else {
                    console.log(
                        "[DEBUG] Cache miss. Fetching survival mastery..."
                    );

                    // Fetch Survival Mastery
                    const survivalResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/survival-mastery/${platform}/${accountId}`
                    );

                    if (!survivalResponse.ok) {
                        throw new Error(
                            "Failed to fetch survival mastery data"
                        );
                    }

                    const survivalData = await survivalResponse.json();
                    console.log(
                        "[DEBUG] Survival mastery data fetched:",
                        survivalData
                    );

                    // Save data to cache
                    localStorage.setItem(
                        survivalCacheKey,
                        JSON.stringify(survivalData)
                    );
                    setSurvivalMastery(survivalData);
                }
            } catch (err) {
                console.error("[DEBUG] Error fetching survival mastery:", err);
                setSurvivalError((err as Error).message);
            } finally {
                setSurvivalLoading(false);
            }

            try {
                // Define the cache key
                const weaponCacheKey = `weapon-mastery-${platform}-${accountId}`;
                console.log(
                    `[DEBUG] Checking cache for key: ${weaponCacheKey}`
                );

                // Check if data is in cache
                const cachedWeapon = localStorage.getItem(weaponCacheKey);
                if (cachedWeapon) {
                    console.log("[DEBUG] Cache hit for weapon mastery.");
                    setWeaponMastery(JSON.parse(cachedWeapon));
                } else {
                    console.log(
                        "[DEBUG] Cache miss. Fetching weapon mastery..."
                    );

                    // Fetch Weapon Mastery
                    const weaponResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/weapon-mastery/${platform}/${accountId}`
                    );

                    if (!weaponResponse.ok) {
                        throw new Error("Failed to fetch weapon mastery data");
                    }

                    const weaponData = await weaponResponse.json();
                    console.log(
                        "[DEBUG] Weapon mastery data fetched:",
                        weaponData
                    );

                    // Save data to cache
                    localStorage.setItem(
                        weaponCacheKey,
                        JSON.stringify(weaponData)
                    );
                    setWeaponMastery(weaponData);
                }
            } catch (err) {
                console.error("[DEBUG] Error fetching weapon mastery:", err);
                setWeaponError((err as Error).message);
            } finally {
                setWeaponLoading(false);
            }
        };

        console.log("[DEBUG] Starting loadAdditionalData...");
        loadAdditionalData();
    }, [accountId, platform, playerName]);

    useEffect(() => {
        if (!clanId) {
            console.log("[DEBUG] clanId is null, skipping loadClanData.");
            return;
        }

        const loadClanData = async () => {
            try {
                const clanCacheKey = `clan-${platform}-${clanId}`;
                console.log(`[DEBUG] Checking cache for clan: ${clanCacheKey}`);
                const cachedClan = localStorage.getItem(clanCacheKey);

                if (cachedClan) {
                    console.log("[DEBUG] Cache hit for clan.");
                    const clanData: ClanInfo = JSON.parse(cachedClan);
                    setClanInfo(clanData);
                } else {
                    console.log(
                        "[DEBUG] Cache miss. Fetching clan data from API."
                    );
                    setClanLoading(true);
                    setClanError(null);

                    const clanResponse = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/clans/${platform}/${clanId}`
                    );

                    if (!clanResponse.ok) {
                        throw new Error("Failed to fetch clan data");
                    }

                    const clanData: ClanInfo = await clanResponse.json();
                    console.log("[DEBUG] Clan data fetched:", clanData);

                    localStorage.setItem(
                        clanCacheKey,
                        JSON.stringify(clanData)
                    );
                    setClanInfo(clanData);
                }
            } catch (err) {
                console.error("[DEBUG] Error fetching clan data:", err);
                setClanError((err as Error).message);
            } finally {
                setClanLoading(false);
            }
        };

        console.log("[DEBUG] Starting loadClanData...");
        loadClanData();
    }, [clanId, platform]);

    const renderTable = (
        clips: Clip[],
        title: string,
        page: number,
        rowsPerPage: number,
        handlePageChange: (event: unknown, newPage: number) => void,
        handleRowsPerPageChange: (
            event: React.ChangeEvent<HTMLInputElement>
        ) => void,
        customFirstColumnTitle: string = "Killed"
    ) => (
        <Box sx={{ flex: 1, padding: 2 }}>
            <Typography variant="h6" textAlign="center" gutterBottom>
                {title}
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{customFirstColumnTitle}</TableCell>
                            <TableCell>Game Mode</TableCell>
                            <TableCell>Map</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clips
                            .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            .map((clip, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {customFirstColumnTitle === "Killed By"
                                            ? clip.killer
                                            : clip.victim}
                                    </TableCell>
                                    <TableCell>{clip.mode}</TableCell>
                                    <TableCell>
                                        {mapNameMapping[clip.map] || clip.map}
                                    </TableCell>
                                    <TableCell>{clip.date}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={clips.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </Box>
    );

    return (
        <Box>
            {/* Player Profile Header */}
            <Box>
                {/* Ban Message Section */}
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 2,
                    }}
                >
                    <Box
                        sx={{
                            maxWidth:
                                banType === "Innocent" ? "300px" : "500px", // Smaller width for no ban
                            textAlign: "center",
                            padding: banType === "Innocent" ? 1 : 2, // Reduced padding for no ban
                            borderRadius: 2,
                            color: "black", // Subtle color for no ban
                            backgroundColor: banType
                                ? banType === "PermanentBan"
                                    ? COLORS.red
                                    : banType === "TemporaryBan"
                                    ? COLORS.orange
                                    : COLORS.green
                                : "transparent",
                            boxShadow:
                                banType === "PermanentBan" ||
                                banType === "TemporaryBan"
                                    ? "0px 4px 10px rgba(0, 0, 0, 0.3)"
                                    : "0px 2px 5px rgba(0, 0, 0, 0.2)", // Subtle shadow for no ban
                            border:
                                banType === "PermanentBan" ||
                                banType === "TemporaryBan"
                                    ? "2px solid black"
                                    : "none", // No border for "Innocent"
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: 1,
                            fontSize:
                                banType === "Innocent" ? "0.85rem" : "1rem", // Smaller font size for "Innocent"
                        }}
                    >
                        <Typography
                            variant={banType === "Innocent" ? "body2" : "h5"} // Smaller text for "Innocent"
                            sx={{
                                fontWeight:
                                    banType === "Innocent" ? "normal" : "bold", // Less emphasis for "Innocent"
                            }}
                        >
                            {banType === "PermanentBan"
                                ? "⚠️ Permanent Ban"
                                : banType === "TemporaryBan"
                                ? "⚠️ Temporary Ban"
                                : "✅ No Active Ban"}
                        </Typography>
                    </Box>
                </Box>

                {/* Player Profile Header */}
                {!accountLoading && survivalMastery ? (
                    <Box
                        sx={{
                            display: "flex", // Use flexbox for horizontal alignment
                            flexDirection: {
                                xs: "column", // Stack vertically on mobile
                                sm: "row", // Align horizontally on larger screens
                            },
                            justifyContent: "center", // Center elements horizontally
                            alignItems: "center", // Center elements vertically
                            gap: 2, // Add spacing between "Player Profile" and PlayerTag
                            marginTop: 2, // Space from the top
                        }}
                    >
                        {/* Player Profile Text */}
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center", // Align both horizontally and vertically
                                gap: 1, // Gap between the logo and text
                            }}
                        >
                            <img
                                src={getPlatformLogo(platform)}
                                alt={`${platform} logo`}
                                style={{
                                    width: 26,
                                    height: 26,
                                }}
                            />
                            <Typography
                                variant="h5"
                                sx={{
                                    textAlign: "center", // Ensures the text itself is centered
                                    whiteSpace: "nowrap", // Prevents text wrapping
                                }}
                            >
                                Player Profile for
                            </Typography>
                        </Box>

                        {/* PlayerTag Component */}
                        <PlayerTag
                            playerName={playerName}
                            survivalTier={survivalMastery?.tier}
                            survivalLevel={survivalMastery?.level}
                            clanInfo={clanInfo}
                        />
                        {isAuthenticated && (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: 2,
                                }}
                            >
                                <IconButton
                                    onClick={() => handleToggleSave()}
                                    sx={{
                                        marginBottom: 1.5,
                                        color: isSaved ? "red" : "gray",
                                    }}
                                >
                                    {isSaved ? (
                                        <FavoriteIcon />
                                    ) : (
                                        <FavoriteBorderIcon />
                                    )}
                                </IconButton>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <Typography
                        variant="h5"
                        sx={{
                            textAlign: "center",
                            marginTop: 4,
                        }}
                    >
                        Loading Player Profile...
                    </Typography>
                )}
            </Box>

            <Box
                sx={{
                    display: "flex",
                    maxWidth: 800,
                    flexDirection: { xs: "column", md: "row" }, // Responsive layout: column for mobile, row for desktop
                    justifyContent: "space-between", // Space between the two sections
                    alignItems: { xs: "center", md: "flex-start" },
                    gap: { xs: 2, md: 4 }, // Smaller gap on mobile, larger on desktop
                    margin: "16px auto",
                    backgroundColor: "#2e2e2e", // Unified background for the container
                    borderRadius: "8px", // Unified border radius
                    padding: 2, // Padding inside the container
                }}
            >
                {/* Clan Info Section */}
                {!clanLoading && clanInfo && (
                    <Box
                        sx={{
                            flex: 1, // Equal space for both sections
                            padding: 2,
                            backgroundColor: "#2e2e2e", // Matches the parent container
                            borderRadius: { xs: "8px", md: "8px 0 0 8px" }, // Rounded corners for mobile, left corner rounded for desktop
                            borderRight: { md: "1px solid #444" }, // Border between sections on desktop
                            borderBottom: { xs: "1px solid #444", md: "none" },
                            flexShrink: 0, // Prevent shrinking
                        }}
                    >
                        {clanLoading && <CircularProgress />}
                        {clanError && (
                            <Typography color="error">{clanError}</Typography>
                        )}
                        <Typography
                            variant="h5"
                            textAlign="center"
                            gutterBottom
                        >
                            Clan Info
                        </Typography>
                        <Typography>Clan Name: {clanInfo.clanName}</Typography>
                        <Typography>Clan Tag: {clanInfo.clanTag}</Typography>
                        <Typography>
                            Clan Level: {clanInfo.clanLevel}
                        </Typography>
                        <Typography>
                            Members: {clanInfo.clanMemberCount}
                        </Typography>
                    </Box>
                )}

                {/* Survival Mastery Info Section */}
                {!survivalLoading && survivalMastery && (
                    <Box
                        sx={{
                            flex: 1, // Equal space for both sections
                            padding: 2,
                            backgroundColor: "#2e2e2e", // Matches the parent container
                            borderRadius: { xs: "8px", md: "0 8px 8px 0" }, // Rounded corners for mobile, right corner rounded for desktop
                            flexShrink: 0,
                        }}
                    >
                        <Typography
                            variant="h5"
                            textAlign="center"
                            gutterBottom
                        >
                            Survival Mastery
                        </Typography>
                        <Typography>Tier: {survivalMastery.tier}</Typography>
                        <Typography>Level: {survivalMastery.level}</Typography>
                        <SurvivalMasteryXP xp={survivalMastery.xp} />
                    </Box>
                )}
            </Box>

            {/* Weapon Mastery */}
            <Box
                sx={{
                    maxWidth: 1200, // Limit the width to 60% of the viewport
                    margin: "0 auto", // Center the box horizontally
                }}
            >
                <Typography
                    variant="h5"
                    sx={{ textAlign: "center", marginBottom: 1 }}
                >
                    Weapon Mastery
                </Typography>

                {weaponLoading && <CircularProgress />}
                {weaponError && (
                    <Typography color="error">{weaponError}</Typography>
                )}

                {!weaponLoading && weaponMastery && (
                    <WeaponMasterySection weaponMastery={weaponMastery} />
                )}
            </Box>

            {/* PUBG Report Clips */}
            {clipsLoading && <CircularProgress />}
            {clipsError && <Typography color="error">{clipsError}</Typography>}
            {!clipsLoading && !clipsError && (
                <Box sx={{ marginBottom: 4 }}>
                    {/* <Box
                        sx={{
                            maxWidth: "60vw",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: 2,
                            backgroundColor: "#282c34",
                            borderRadius: "8px",
                            margin: "0 auto",
                            marginTop: 4,
                        }}
                    >
                        <IconButton
                            onClick={() => setShowTables(!showTables)}
                            sx={{ color: "white" }}
                        >
                            {showTables ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        <Typography
                            variant="h5"
                            sx={{
                                textAlign: "center",
                                color: "white",
                                flex: 1,
                            }}
                        >
                            PUBG Report Details
                        </Typography>
                        <a
                            href={`https://pubg.report/players/${accountId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={pubgReportLogo}
                                alt="PUBG Report Logo"
                                style={{ width: 48, height: 48 }}
                            />
                        </a>
                    </Box> */}

                    {showTables && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                gap: 4,
                                margin: "0 auto",
                                maxWidth: 1400,
                            }}
                        >
                            {renderTable(
                                killerClips,
                                "Clips Where You Are the Killer",
                                killerPage,
                                killerRowsPerPage,
                                (event, newPage) => setKillerPage(newPage),
                                (event) => {
                                    setKillerRowsPerPage(
                                        parseInt(event.target.value, 10)
                                    );
                                    setKillerPage(0);
                                }
                            )}
                            {renderTable(
                                victimClips,
                                "Clips Where You Are the Victim",
                                victimPage,
                                victimRowsPerPage,
                                (event, newPage) => setVictimPage(newPage),
                                (event) => {
                                    setVictimRowsPerPage(
                                        parseInt(event.target.value, 10)
                                    );
                                    setVictimPage(0);
                                },
                                "Killed By" // Pass custom column title for the victim table
                            )}
                        </Box>
                    )}
                    {open && (
                        <Dialog
                            open={open}
                            onClose={() => setOpen(false)}
                            sx={{ "& .MuiDialog-paper": { padding: 2 } }}
                        >
                            <DialogTitle>Save Player To</DialogTitle>
                            <Select
                                value={selectedCategory}
                                onChange={(e) =>
                                    setSelectedCategory(e.target.value)
                                }
                                fullWidth
                            >
                                <MenuItem value="My Accounts">
                                    My Accounts
                                </MenuItem>
                                <MenuItem value="My Friends">
                                    My Friends
                                </MenuItem>
                                <MenuItem value="Following">Following</MenuItem>
                            </Select>
                            <DialogActions>
                                <Button onClick={() => setOpen(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    onClick={() => {
                                        setOpen(false);
                                        handleToggleSave(selectedCategory); // Pass the selected category
                                    }}
                                    variant="contained"
                                >
                                    Save
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PlayerProfile;

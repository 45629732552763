export const esportsMaps = [
    {
        name: "Erangel",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Erangel_Main_High_Res.png",
        size: 8,
    },
    {
        name: "Miramar",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Miramar_Main_High_Res.png",
        size: 8,
    },
    {
        name: "Vikendi",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Vikendi_Main_High_Res.png",
        size: 6,
    },
    {
        name: "Taego",
        url: "https://media.githubusercontent.com/media/pubg/api-assets/master/Assets/Maps/Taego_Main_High_Res.png",
        size: 8,
    },
];

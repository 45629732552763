import { ImageItem } from "../types/weaponItem";

export const images: ImageItem[] = [
    // Crate Weapons
    {
        id: 1,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_FAMASG2_C.png",
        title: "FAMASG2",
        crateWeapon: true,
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 2,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Groza_C.png",
        title: "Groza",
        crateWeapon: true,
        ammoType: "762",
        weaponType: "AR",
    },
    {
        id: 3,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_AWM_C.png",
        title: "AWM",
        crateWeapon: true,
        ammoType: ".300",
        weaponType: "SR",
    },
    {
        id: 4,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_L6_C.png",
        title: "Lynx AMR",
        crateWeapon: true,
        ammoType: "50cal",
        weaponType: "SR",
    },
    {
        id: 5,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_MG3_C.png",
        title: "MG3",
        crateWeapon: true,
        ammoType: "762",
        weaponType: "LMG",
    },
    {
        id: 6,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mk14_C.png",
        title: "Mk14",
        crateWeapon: true,
        ammoType: "762",
        weaponType: "DMR",
    },
    {
        id: 7,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_P90_C.png",
        title: "P90",
        crateWeapon: true,
        ammoType: "57",
        weaponType: "SMG",
    },

    // AR Weapons
    {
        id: 8,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_ACE32_C.png",
        title: "ACE32",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        id: 9,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_AK47_C.png",
        title: "AK47",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        id: 10,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_AUG_C.png",
        title: "AUG",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 11,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_BerylM762_C.png",
        title: "Beryl",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        id: 12,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_G36C_C.png",
        title: "G36",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 13,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_HK416_C.png",
        title: "M416",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 14,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_K2_C.png",
        title: "K2",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 15,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_M16A4_C.png",
        title: "M16A4",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 16,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mk47Mutant_C.png",
        title: "Mk47 Mutant",
        ammoType: "762",
        weaponType: "AR",
    },
    {
        id: 17,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_QBZ95_C.png",
        title: "QBZ95",
        ammoType: "556",
        weaponType: "AR",
    },
    {
        id: 18,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_SCAR-L_C.png",
        title: "SCAR-L",
        ammoType: "556",
        weaponType: "AR",
    },

    // DMR Weapons
    {
        id: 19,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Dragunov_C.png",
        title: "Dragunov",
        ammoType: "762",
        weaponType: "DMR",
    },
    {
        id: 20,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mini14_C.png",
        title: "Mini 14",
        ammoType: "556",
        weaponType: "DMR",
    },
    {
        id: 21,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Mk12_C.png",
        title: "Mk12",
        ammoType: "556",
        weaponType: "DMR",
    },
    {
        id: 22,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_QBU88_C.png",
        title: "QBU",
        ammoType: "556",
        weaponType: "DMR",
    },
    {
        id: 23,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_SKS_C.png",
        title: "SKS",
        ammoType: "762",
        weaponType: "DMR",
    },

    // SR Weapons
    {
        id: 24,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_Kar98k_C.png",
        title: "Kar 98",
        ammoType: "762",
        weaponType: "SR",
    },
    {
        id: 25,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_M24_C.png",
        title: "M24",
        ammoType: "762",
        weaponType: "SR",
    },
    {
        id: 26,
        src: "https://raw.githubusercontent.com/pubg/api-assets/refs/heads/master/Assets/Item/Weapon/Main/Item_Weapon_DP28_C.png",
        title: "DP28",
        ammoType: "762",
        weaponType: "LMG",
    },
];

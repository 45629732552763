import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Paper,
    Button,
    CircularProgress,
    IconButton,
} from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import MatchCard from "components/MatchCard";
import { Match } from "types/match";
import { loadFromCache, saveToCache } from "api/cacheUtils";
import { enrichMatchData } from "utils/enrichMatchData";

// Google Analytics
import ReactGA from "react-ga4";

/// Heart Icons
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useAuth } from "AuthContext";
import { addSeconds, format } from "date-fns";
import { COLORS } from "constants/colors";

const MatchDetails: React.FC = () => {
    const { matchId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const [accountId, setAccountId] = useState<string | null>(null);
    const searchParams = new URLSearchParams(location.search);
    const playerName = searchParams.get("name") || "Unknown Player";
    const platform = searchParams.get("platform") || "steam";

    const [matchData, setMatchData] = useState<Match | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Save Match
    const [isSaved, setIsSaved] = useState(false);
    const { isAuthenticated } = useAuth();

    const [rosterSortField, setRosterSortField] = useState<
        "rank" | "totalKills" | "totalDamage"
    >("rank");
    const [rosterSortOrder, setRosterSortOrder] = useState<"asc" | "desc">(
        "asc"
    );

    const [participantSortField, setParticipantSortField] = useState<
        "winPlace" | "kills" | "damageDealt"
    >("winPlace");
    const [participantSortOrder, setParticipantSortOrder] = useState<
        "asc" | "desc"
    >("asc");

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/matches/",
            title: "Match Details Page",
        });
    }, []);

    // Fetch account ID (if not cached)
    useEffect(() => {
        const fetchAccountId = async () => {
            setError(null);

            const cacheKey = `account-${platform}-${playerName}`;
            const cachedAccountId = loadFromCache(cacheKey);

            if (cachedAccountId) {
                setAccountId(cachedAccountId);
                return;
            }

            try {
                const accountResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
                );

                if (!accountResponse.ok) {
                    throw new Error(
                        `Failed to fetch player info for ${playerName}.`
                    );
                }

                const accountData = await accountResponse.json();
                setAccountId(accountData.accountId);

                // Cache the account ID for future use
                saveToCache(cacheKey, accountData.accountId);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching player account."
                );
            }
        };

        fetchAccountId();
    }, [platform, playerName]);

    // Fetch match details
    useEffect(() => {
        const fetchMatchDetails = async () => {
            setLoading(true);
            setError(null);

            try {
                const matchResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/match/${platform}/${matchId}`
                );

                if (!matchResponse.ok) {
                    throw new Error(
                        `Failed to fetch details for match ID: ${matchId}`
                    );
                }

                const matchData = await matchResponse.json();

                const enrichedData = enrichMatchData(
                    matchData,
                    accountId,
                    playerName
                );

                setMatchData(enrichedData);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching match details."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchMatchDetails();
    }, [matchId, platform, playerName, accountId]);

    const checkIfMatchSaved = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/saved-matches`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error("Failed to fetch saved matches");
            }

            const data = await response.json();
            const matchSaved = data.matches.some(
                (match: { matchId: string; platform: string }) =>
                    match.matchId === matchId && match.platform === platform
            );

            setIsSaved(matchSaved);
        } catch (error) {
            console.error("Error checking saved match:", error);
        }
    };

    const handleToggleSave = async () => {
        try {
            const token = localStorage.getItem("authToken");

            if (isSaved) {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/remove-match`,
                    {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({ matchId, platform }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to remove match");
                }

                setIsSaved(false);
            } else {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/save-match`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            matchId,
                            platform,
                            matchData,
                            playerName,
                        }),
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to save match");
                }

                setIsSaved(true);
            }
        } catch (error) {
            console.error("Error toggling save:", error);
            alert("An error occurred while toggling the saved state.");
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            checkIfMatchSaved();
        }
    }, [isAuthenticated, matchId, platform]);

    const handleRosterSort = (field: "rank" | "totalKills" | "totalDamage") => {
        if (rosterSortField === field) {
            setRosterSortOrder(rosterSortOrder === "asc" ? "desc" : "asc");
        } else {
            setRosterSortField(field);
            setRosterSortOrder("asc");
        }
    };

    const handleParticipantSort = (
        field: "winPlace" | "kills" | "damageDealt"
    ) => {
        if (participantSortField === field) {
            setParticipantSortOrder(
                participantSortOrder === "asc" ? "desc" : "asc"
            );
        } else {
            setParticipantSortField(field);
            setParticipantSortOrder("asc");
        }
    };

    const sortData = <T,>(
        data: T[],
        field: keyof T,
        order: "asc" | "desc"
    ): T[] => {
        return [...data].sort((a, b) => {
            const aValue = a[field] as number;
            const bValue = b[field] as number;

            return order === "asc" ? aValue - bValue : bValue - aValue;
        });
    };

    if (loading) return <CircularProgress />;
    if (error)
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );

    if (!matchData) return null;

    const { rosters, participants, mapName, gameMode, duration, createdAt } =
        matchData;

    return (
        <Box
            sx={{
                maxWidth: 1200,
                margin: "0 auto",

                display: "flex",
                flexDirection: "column",
                gap: 3,
            }}
        >
            <Button
                variant="contained"
                sx={{
                    mt: 2,

                    backgroundColor: COLORS.orange,
                    fontWeight: "bold",
                    ":hover": {
                        backgroundColor: COLORS.darkOrange,
                    },
                }}
                onClick={() =>
                    navigate(
                        `/player-stats/matches?platform=${encodeURIComponent(
                            platform
                        )}&name=${encodeURIComponent(playerName)}`
                    )
                }
            >
                Back to Matches
            </Button>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center", // Center items vertically
                    justifyContent: "center", // Center items horizontally
                    gap: 2, // Add spacing between the elements
                    marginTop: 2, // Optional: Add some margin at the top
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Match Details
                </Typography>
                <IconButton
                    onClick={handleToggleSave}
                    sx={{ color: isSaved ? "red" : "gray", marginBottom: 1 }}
                >
                    {isSaved ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
            </Box>

            {/* Add Detailed MatchCard */}
            <MatchCard
                match={matchData}
                accountId={accountId}
                viewType="detailed"
                platform={platform}
                playerName={playerName}
                showViewDetailsButton={false}
            />

            <Typography variant="body1">
                Match Date:{" "}
                {format(new Date(createdAt), "MMMM d, yyyy hh:mm:ss a")} -{" "}
                {format(
                    addSeconds(new Date(createdAt), duration),
                    "hh:mm:ss a"
                )}
            </Typography>

            {/* Rosters Table */}
            <Box>
                <Typography variant="h6">Teams</Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 500,
                        overflowY: "auto",
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Members</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => handleRosterSort("rank")}
                                    >
                                        Placement
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleRosterSort("totalKills")
                                        }
                                    >
                                        Kills
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleRosterSort("totalDamage")
                                        }
                                    >
                                        Damage
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortData(
                                rosters,
                                rosterSortField,
                                rosterSortOrder
                            ).map((roster) => (
                                <TableRow key={roster.id}>
                                    <TableCell>
                                        Team{"# "}
                                        {roster.rank}
                                    </TableCell>
                                    <TableCell>{roster.rank}</TableCell>
                                    <TableCell>{roster.totalKills}</TableCell>
                                    <TableCell>
                                        {roster.totalDamage?.toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            {/* Participants Table */}
            <Box>
                <Typography variant="h6">Players</Typography>
                <TableContainer
                    component={Paper}
                    sx={{
                        maxHeight: 500,
                        overflowY: "auto",
                    }}
                >
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Player</TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleParticipantSort("winPlace")
                                        }
                                    >
                                        Placement
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleParticipantSort("kills")
                                        }
                                    >
                                        Kills
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() =>
                                            handleParticipantSort("damageDealt")
                                        }
                                    >
                                        Damage
                                    </Button>
                                </TableCell>
                                <TableCell>Survival Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortData(
                                participants,
                                participantSortField,
                                participantSortOrder
                            ).map((participant) => (
                                <TableRow key={participant.id}>
                                    <TableCell>{participant.name}</TableCell>
                                    <TableCell>
                                        {participant.winPlace}
                                    </TableCell>
                                    <TableCell>{participant.kills}</TableCell>
                                    <TableCell>
                                        {participant.damageDealt.toFixed(2)}
                                    </TableCell>
                                    <TableCell>
                                        {Math.floor(
                                            participant.timeSurvived / 60
                                        )}
                                        m {participant.timeSurvived % 60}s
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default MatchDetails;

import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
    children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
        return <Navigate to="/login" />;
    }
    return children;
};

export default ProtectedRoute;

// Default Cache expiration time in milliseconds (30 minutes)
const DEFAULT_CACHE_EXPIRATION = 5 * 60 * 1000;

// Maximum allowable size for cached data in bytes (approx. 5MB localStorage limit)
const MAX_CACHE_SIZE = 5000000;

// Helper: Save data to cache with optional expiration time
export const saveToCache = (
    key: string,
    data: any,
    expiration = DEFAULT_CACHE_EXPIRATION
) => {
    try {
        const serializedData = JSON.stringify({
            data,
            timestamp: Date.now(),
            expiration,
        });

        const dataSize = new Blob([serializedData]).size; // Calculate size in bytes

        if (dataSize > MAX_CACHE_SIZE) {
            console.warn(
                `Skipping caching for key "${key}" because the data size (${dataSize} bytes) exceeds the allowable limit.`
            );
            return false;
        }

        localStorage.setItem(key, serializedData);
        console.log(`Data successfully cached for key "${key}".`);
        return true;
    } catch (error) {
        console.error(`Error saving data to cache for key "${key}":`, error);
        return false;
    }
};

// Helper: Load data from cache
export const loadFromCache = (key: string) => {
    try {
        const cacheItem = localStorage.getItem(key);
        if (!cacheItem) return null;

        const { data, timestamp, expiration } = JSON.parse(cacheItem);
        const now = Date.now();

        if (now - timestamp > expiration) {
            console.log(`Cache expired for key "${key}". Removing cache.`);
            localStorage.removeItem(key); // Remove expired cache
            return null;
        }

        return data;
    } catch (error) {
        console.error(`Error loading cache for key "${key}":`, error);
        localStorage.removeItem(key); // Remove corrupted cache
        return null;
    }
};

// Helper: Remove data from cache
export const removeFromCache = (key: string) => {
    try {
        localStorage.removeItem(key);
        console.log(`Cache cleared for key "${key}".`);
    } catch (error) {
        console.error(`Error removing cache for key "${key}":`, error);
    }
};

// Helper: Clear all cache
export const clearAllCache = () => {
    try {
        localStorage.clear();
        console.log("All cache cleared.");
    } catch (error) {
        console.error("Error clearing all cache:", error);
    }
};

import React, { useState } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Paper,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { COLORS } from "constants/colors";

interface WeaponStats {
    MostDefeatsInAGame?: number;
    Defeats?: number;
    MostDamagePlayerInAGame?: number;
    DamagePlayer?: number;
    MostHeadShotsInAGame?: number;
    HeadShots?: number;
    LongestDefeat?: number;
    LongRangeDefeats?: number;
    Kills?: number;
    MostKillsInAGame?: number;
    Groggies?: number;
    MostGroggiesInAGame?: number;
    LongestKill?: number;
}

interface WeaponInfo {
    weaponId: string;
    XPTotal: number;
    LevelCurrent: number;
    TierCurrent: number;
    StatsTotal?: WeaponStats; // Legacy
    OfficialStatsTotal?: WeaponStats; // Normal
    CompetitiveStatsTotal?: WeaponStats; // Ranked
}

export interface WeaponCategories {
    "Assault Rifles": WeaponInfo[];
    "Submachine Guns": WeaponInfo[];
    "Sniper Rifles": WeaponInfo[];
    "Designated Marksman Rifles": WeaponInfo[];
    "Light Machine Guns": WeaponInfo[];
    Shotguns: WeaponInfo[];
    Pistols: WeaponInfo[];
    Throwables: WeaponInfo[];
    Miscellaneous: WeaponInfo[];
}

const WeaponMasterySection: React.FC<{
    weaponMastery: WeaponCategories | null;
}> = ({ weaponMastery }) => {
    const [selectedStatType, setSelectedStatType] = useState<
        "Normal" | "Ranked" | "Legacy"
    >("Normal");

    const formatWeaponName = (weaponId: string): string => {
        const match = weaponId.match(/Weapon_(.+?)_C/);
        return match ? match[1].replace(/_/g, " ") : weaponId;
    };

    const renderStatsTable = (
        stats: WeaponStats | undefined,
        isLegacy: boolean
    ) => {
        if (!stats) return <Typography>No stats available</Typography>;

        const rows = isLegacy
            ? [
                  ["Kills", stats.Kills],
                  ["Most Kills in a Game", stats.MostKillsInAGame],
                  ["Defeats", stats.Defeats],
                  ["Most Defeats in a Game", stats.MostDefeatsInAGame],
                  [
                      "Total Damage",
                      stats.DamagePlayer
                          ? Number(stats.DamagePlayer).toLocaleString(
                                undefined,
                                {
                                    maximumFractionDigits: 0,
                                }
                            )
                          : undefined,
                  ],
                  ["Headshots", stats.HeadShots],
                  [
                      "Longest Defeat",
                      stats.LongestDefeat
                          ? `${Number(stats.LongestDefeat).toLocaleString(
                                undefined,
                                { maximumFractionDigits: 0 }
                            )} m`
                          : undefined,
                  ],
                  ["Long Range Defeats", stats.LongRangeDefeats],
                  ["Knockdowns", stats.Groggies],
                  ["Most Knockdowns in a Game", stats.MostGroggiesInAGame],
              ]
            : [
                  ["Kills", stats.Kills],
                  ["Most Kills in a Game", stats.MostKillsInAGame],
                  [
                      "Total Damage",
                      stats.DamagePlayer
                          ? Number(stats.DamagePlayer).toLocaleString(
                                undefined,
                                {
                                    maximumFractionDigits: 0,
                                }
                            )
                          : undefined,
                  ],
                  ["Headshots", stats.HeadShots],
                  [
                      "Longest Kill",
                      stats.LongestKill
                          ? `${Number(stats.LongestKill).toLocaleString(
                                undefined,
                                { maximumFractionDigits: 0 }
                            )} m`
                          : undefined,
                  ],
                  ["Knockdowns", stats.Groggies],
              ];

        return (
            <TableContainer
                sx={{
                    backgroundColor: "#2e2e2e",
                    borderRadius: "8px",
                    border: "1px solid #444",
                    overflowX: "auto", // Ensure responsiveness for very wide tables
                }}
            >
                <Table>
                    <TableBody>
                        {rows.map(
                            ([label, value]) =>
                                value !== undefined && (
                                    <TableRow key={label}>
                                        <TableCell
                                            sx={{
                                                textAlign: "left",
                                                color: "#fff",
                                                padding: "8px 16px", // Add spacing
                                                borderBottom: "1px solid #444",
                                            }}
                                        >
                                            <strong>{label}</strong>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                textAlign: "left",
                                                color: COLORS.silver,
                                                padding: "8px 16px", // Add spacing
                                                borderBottom: "1px solid #444",
                                            }}
                                        >
                                            {value}
                                        </TableCell>
                                    </TableRow>
                                )
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const renderWeaponDetails = (weapons: WeaponInfo[]) => {
        const sortedWeapons = [...weapons].sort((a, b) => {
            if (b.TierCurrent !== a.TierCurrent) {
                return b.TierCurrent - a.TierCurrent;
            }
            return b.LevelCurrent - a.LevelCurrent;
        });

        return sortedWeapons.map((weapon, index) => (
            <Paper
                key={index}
                sx={{
                    padding: 2,
                    marginBottom: 2,
                    backgroundColor: "#3a3a3a",
                    borderRadius: "8px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "column", md: "row" }, // Column on mobile, row on desktop
                        justifyContent: {
                            xs: "flex-start",
                            md: "space-between",
                        },
                        gap: 2,
                        alignItems: { xs: "flex-start", md: "center" }, // Adjust alignment for smaller screens,
                    }}
                >
                    {/* Left Section: Weapon Details */}
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex", // Ensures flex container behavior
                            flexDirection: "column", // Stack items vertically
                            alignItems: "center", // Center items horizontally
                            justifyContent: "center", // Center items vertically
                            textAlign: "center", // Ensures text is centered
                            width: "100%",
                        }}
                    >
                        <Typography>
                            <strong>Weapon:</strong>{" "}
                            {formatWeaponName(weapon.weaponId)}
                        </Typography>
                        {weapon.TierCurrent > 0 && (
                            <img
                                src={require(`../assets/weaponMastery/tier${weapon.TierCurrent}weapon.png`)}
                                alt={`Tier ${weapon.TierCurrent}`}
                                style={{ width: 250, height: 60 }}
                            />
                        )}
                        <Box
                            sx={{
                                display: "flex",

                                alignItems: "center", // Center items horizontally
                                justifyContent: "center", // Ensure centered alignment
                                gap: 2,
                            }}
                        >
                            <Typography>
                                <strong>Level:</strong> {weapon.LevelCurrent}
                            </Typography>
                            <Typography>
                                <strong>XP:</strong>{" "}
                                {Number(weapon.XPTotal).toLocaleString()}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Right Section: Stats Table */}
                    <Box
                        sx={{
                            flex: 1,
                            width: "100%",
                            marginTop: { xs: 2, md: 0 },
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            {selectedStatType} Stats:
                        </Typography>
                        {selectedStatType === "Normal"
                            ? renderStatsTable(weapon.OfficialStatsTotal, false)
                            : selectedStatType === "Ranked"
                            ? renderStatsTable(
                                  weapon.CompetitiveStatsTotal,
                                  false
                              )
                            : renderStatsTable(weapon.StatsTotal, true)}
                    </Box>
                </Box>
            </Paper>
        ));
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 2,
                    mb: 3,
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => setSelectedStatType("Normal")}
                    sx={{
                        backgroundColor:
                            selectedStatType === "Normal"
                                ? COLORS.orange
                                : "transparent",
                        color:
                            selectedStatType === "Normal" ? "black" : "white",
                        border: "1px solid orange",
                        "&:hover": {
                            backgroundColor:
                                selectedStatType === "Normal"
                                    ? "#cc7700"
                                    : "#4d4d4d", // Darker orange for active, lighter orange for hover
                        },
                    }}
                >
                    Normal
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setSelectedStatType("Ranked")}
                    sx={{
                        backgroundColor:
                            selectedStatType === "Ranked"
                                ? COLORS.orange
                                : "transparent",
                        color:
                            selectedStatType === "Ranked" ? "black" : "white",
                        border: "1px solid orange",
                        "&:hover": {
                            backgroundColor:
                                selectedStatType === "Ranked"
                                    ? "#cc7700"
                                    : "#4d4d4d", // Darker orange for active, lighter orange for hover
                        },
                    }}
                >
                    Ranked
                </Button>
                <Button
                    variant="contained"
                    onClick={() => setSelectedStatType("Legacy")}
                    sx={{
                        backgroundColor:
                            selectedStatType === "Legacy"
                                ? COLORS.orange
                                : "transparent",
                        color:
                            selectedStatType === "Legacy" ? "black" : "white",
                        border: "1px solid orange",
                        "&:hover": {
                            backgroundColor:
                                selectedStatType === "Legacy"
                                    ? "#cc7700"
                                    : "#4d4d4d", // Darker orange for active, lighter orange for hover
                        },
                    }}
                >
                    Legacy
                </Button>
            </Box>

            {weaponMastery ? (
                Object.entries(weaponMastery).map(([category, weapons]) => (
                    <Accordion key={category} sx={{ marginBottom: 2 }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{ backgroundColor: "#2e2e2e" }}
                        >
                            <Typography sx={{ textTransform: "capitalize" }}>
                                {category}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "#1e1e1e" }}>
                            {renderWeaponDetails(weapons)}
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <Typography>No weapon mastery data available.</Typography>
            )}
        </Box>
    );
};

export default WeaponMasterySection;

import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useFeatureToggle = () => {
    const location = useLocation();
    const [showTechStack, setShowTechStack] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const showTechQuery = params.has("showTechStack"); // Check for query parameter
        const showTechPath = location.pathname === "/showTechStack"; // Check for the specific pathname

        const shouldShowTech = showTechQuery || showTechPath;

        setShowTechStack(
            shouldShowTech || localStorage.getItem("showTechStack") === "true"
        );

        if (shouldShowTech) {
            localStorage.setItem("showTechStack", "true");
        }
    }, [location]);

    return showTechStack;
};

export default useFeatureToggle;

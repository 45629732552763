import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const DestonSecretRooms: React.FC = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/maps"); // Navigate back to the maps landing page
    };

    return (
        <Box mt={2} mb={4}>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.5 }}
                >
                    {/* Page Title */}
                    <Typography variant="h4" align="center" gutterBottom>
                        Deston Secret Rooms
                    </Typography>

                    {/* Page Description */}
                    <Typography variant="body1" align="center" gutterBottom>
                        Explore the secret locations on Deston and unlock hidden
                        treasures!
                    </Typography>

                    {/* Buttons Section */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "10px",
                            marginBottom: "16px",
                        }}
                    >
                        <Button
                            onClick={handleBack}
                            variant="contained"
                            color="primary"
                        >
                            Back to Categories
                        </Button>
                    </Box>

                    {/* Image Section */}
                    <Box
                        sx={{
                            width: { xs: "98%", sm: "80%", md: "60%" }, // Responsive width
                            overflow: "hidden", // Ensures content does not overflow
                            margin: "0 auto", // Center the container
                            position: "relative",
                        }}
                    >
                        <Box
                            component="img"
                            src="https://www.razzem.com/wp-content/uploads/2023/04/pubg-deston-secret-rooms-locations-and-security-keys-1-1536x1536.jpg"
                            alt="Deston Secret Rooms"
                            sx={{
                                width: "100%", // Image takes up the full width of the container
                                height: "auto", // Maintain aspect ratio
                                objectFit: "cover", // Ensure the image covers the container
                            }}
                        />
                    </Box>
                </motion.div>
            </AnimatePresence>
        </Box>
    );
};

import React, { useEffect } from "react";
import { Box, Typography, Card, CardContent, Link } from "@mui/material";
import {
    esports,
    generalWiki,
    matchReplays,
    pubgReport,
    youTubers,
} from "constants/resources";
import ReactGA from "react-ga4";

export interface Resource {
    name: string;
    link: string;
    description: string;
}

interface ResourcesSectionProps {
    title: string;
    resources: Resource[];
}

const ResourcesSection: React.FC<ResourcesSectionProps> = ({
    title,
    resources,
}) => {
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/resources",
            title: "Resources Page",
        });
    }, []);

    return (
        <Box sx={{ marginBottom: 4 }}>
            <Typography
                variant="h6"
                sx={{ marginBottom: 2, fontWeight: "bold" }}
            >
                {title}
            </Typography>
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        xs: "1fr",
                        sm: "repeat(auto-fit, minmax(250px, 1fr))",
                    },
                    gap: 2,
                    justifyContent: "center", // Center the grid items horizontally
                }}
            >
                {resources.map((resource, index) => (
                    <Card key={index} sx={{ height: "100%" }}>
                        <CardContent>
                            <Typography
                                sx={{ fontWeight: "bold", marginBottom: 1 }}
                            >
                                {resource.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ marginBottom: 2 }}
                            >
                                {resource.description}
                            </Typography>
                            <Link
                                href={resource.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    textDecoration: "none",
                                    color: "primary",
                                }}
                            >
                                {resource.link}
                            </Link>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    );
};

const Resources: React.FC = () => {
    return (
        <Box sx={{ padding: 2, maxWidth: "1200px", margin: "auto" }}>
            <Typography
                variant="h5"
                sx={{
                    textAlign: "center",
                    marginBottom: 2,
                    fontWeight: "bold",
                }}
            >
                More Resources
            </Typography>

            <ResourcesSection
                title="Official PUBG Wiki"
                resources={generalWiki}
            />
            <ResourcesSection title="Esports Resources" resources={esports} />
            <ResourcesSection
                title="2D Match Replays"
                resources={matchReplays}
            />
            <ResourcesSection title="PUBG Report" resources={pubgReport} />
            <ResourcesSection title="YouTubers" resources={youTubers} />
        </Box>
    );
};

export default Resources;

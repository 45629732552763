import React from "react";
import {
    Box,
    Typography,
    Paper,
    ListItemText,
    ListItem,
    List,
} from "@mui/material";
import { versionHistory } from "constants/versionHistory";
import { COLORS } from "constants/colors";

const VersionHistory: React.FC = () => {
    return (
        <Box
            sx={{
                padding: "20px",
                maxWidth: "900px",
                margin: "0 auto",
                textAlign: "center",
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    color: COLORS.orange,
                    marginBottom: "20px",
                    fontWeight: "bold",
                }}
            >
                Upcoming Features
            </Typography>
            <Paper
                elevation={3}
                sx={{
                    marginBottom: "20px",
                    textAlign: "left",
                }}
            >
                <List>
                    <ListItem>
                        <ListItemText primary="- In-depth match details analysis using telemetry" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Esports matches details" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary="- Finish adding all weapons to Tier List Maker" />
                    </ListItem>
                </List>
            </Paper>
            {/* Page Header */}
            <Typography variant="h5" gutterBottom>
                Version History
            </Typography>

            {/* Render Versions */}
            {versionHistory.map((version, index) => (
                <Paper
                    key={index}
                    elevation={3}
                    sx={{
                        padding: "16px",
                        marginBottom: "16px",
                        textAlign: "left",
                    }}
                >
                    {/* Version Title */}
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                        {version.version}
                    </Typography>
                    {/* Version Description */}
                    <Typography
                        variant="body1"
                        sx={{
                            whiteSpace: "pre-line",
                        }}
                    >
                        {version.description}
                    </Typography>
                </Paper>
            ))}
        </Box>
    );
};

export default VersionHistory;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    TextField,
    Button,
    Select,
    MenuItem,
    Typography,
    FormControl,
    CircularProgress,
    Divider,
    InputLabel,
} from "@mui/material";
import steamLogo from "../../assets/steam.png";
import psnLogo from "../../assets/psn.png";
import xboxLogo from "../../assets/xbox.png";
import { predefinedPlayers } from "./lifetimeStats/PopularPlayersModal";
import ReactGA from "react-ga4";
import { useAuth } from "AuthContext";
import { COLORS } from "constants/colors";

// Platform logos
const platformLogos: { [key: string]: string } = {
    steam: steamLogo,
    psn: psnLogo,
    xbox: xboxLogo,
};

interface TeamData {
    teamName: string | null;
    teamLogo: string | null;
}

const PlayerSearch: React.FC = () => {
    // Basic Search State
    const [playerName, setPlayerName] = useState("");
    const [platform, setPlatform] = useState("steam");

    // Team Data State
    const [teamDataMap, setTeamDataMap] = useState<{
        [playerName: string]: TeamData;
    }>({});

    // Saved Players State
    const [savedPlayers, setSavedPlayers] = useState<{
        myAccounts: { playerName: string; platform: string }[];
        myFriends: { playerName: string; platform: string }[];
        following: { playerName: string; platform: string }[];
    }>({
        myAccounts: [],
        myFriends: [],
        following: [],
    });

    // Loading
    const [loading, setLoading] = useState(true);
    const [loadingSavedPlayers, setLoadingSavedPlayers] = useState(false);

    // Auth and Navigation
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-search",
            title: "Player Search Page",
        });
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            const fetchSavedPlayers = async () => {
                setLoadingSavedPlayers(true);
                try {
                    const token = localStorage.getItem("authToken");
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/saved-players`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );

                    if (!response.ok) {
                        throw new Error("Failed to fetch saved players");
                    }

                    const data = await response.json();
                    setSavedPlayers({
                        myAccounts: data.myAccounts || [],
                        myFriends: data.myFriends || [],
                        following: data.following || [],
                    });
                } catch (error) {
                    console.error("Error fetching saved players:", error);
                } finally {
                    setLoadingSavedPlayers(false);
                }
            };

            fetchSavedPlayers();
        }
    }, [isAuthenticated]);

    const handleSearch = async () => {
        if (playerName.trim()) {
            try {
                const cacheKey = `${platform}-${playerName}`;
                const cachedData = localStorage.getItem(cacheKey);

                if (!cachedData) {
                    // Fetch player data if not cached
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
                    );

                    if (!response.ok) {
                        throw new Error("Failed to fetch player data");
                    }

                    const accountData = await response.json();

                    // Store fetched data in localStorage
                    localStorage.setItem(cacheKey, JSON.stringify(accountData));
                }

                // Navigate to profile with player and platform details
                navigate(
                    `/player-stats/profile?name=${encodeURIComponent(
                        playerName
                    )}&platform=${platform}`
                );
            } catch (error) {
                console.error("Error fetching player data:", error);
                alert("Failed to fetch player data. Please try again.");
            }
        }
    };

    const handleSavedPlayerClick = (playerName: string, platform: string) => {
        navigate(
            `/player-stats/profile?name=${encodeURIComponent(
                playerName
            )}&platform=${platform}`
        );
    };

    const fetchAllTeamData = async () => {
        setLoading(true);
        const newTeamDataMap: { [playerName: string]: TeamData } = {};

        const players =
            predefinedPlayers["Pro Players" as keyof typeof predefinedPlayers];

        for (const player of players) {
            try {
                // Check localStorage for cached data
                const cacheKey = `teamData-${player}`;
                const cachedData = localStorage.getItem(cacheKey);

                if (cachedData) {
                    const { data, timestamp } = JSON.parse(cachedData);
                    const isExpired =
                        Date.now() - timestamp > 24 * 60 * 60 * 1000; // 24 hours
                    if (!isExpired) {
                        // Use cached data if valid
                        newTeamDataMap[player] = data;
                        continue; // Skip fetch if cached data is valid
                    }
                }

                // Fetch fresh data if not cached or expired
                const response = await fetch(
                    `${
                        process.env.REACT_APP_API_URL
                    }/player-team?playerName=${encodeURIComponent(player)}`
                );

                if (response.ok) {
                    const data: TeamData = await response.json();
                    newTeamDataMap[player] = data;

                    // Store fresh data in localStorage
                    localStorage.setItem(
                        cacheKey,
                        JSON.stringify({ data, timestamp: Date.now() })
                    );
                } else {
                    console.error(`Failed to fetch team data for ${player}`);
                }
            } catch (error) {
                console.error(`Error fetching team data for ${player}:`, error);
            }
        }

        setTeamDataMap(newTeamDataMap);
        setLoading(false);
    };

    useEffect(() => {
        fetchAllTeamData();
    }, []);

    const handleRecommendedSearch = (name: string) => {
        setPlayerName(name);
        navigate(
            `/player-stats/profile?name=${encodeURIComponent(
                name
            )}&platform=steam`
        );
    };

    const categoryColors: { [key: string]: string } = {
        "Pro Players": COLORS.blue,
        "YouTubers/Streamers": COLORS.green,
    };

    const categories: ("myAccounts" | "myFriends" | "following")[] = [
        "myAccounts",
        "myFriends",
        "following",
    ];

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
                gap: 3,
            }}
        >
            {/* Saved Players Dropdown */}
            {isAuthenticated && (
                <Box sx={{ width: "100%", maxWidth: "200px" }}>
                    <FormControl
                        fullWidth
                        sx={{
                            backgroundColor: "#2A2A2A",
                            "& .MuiInputLabel-root": {
                                color: COLORS.orange, // Label color when inactive
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: COLORS.orange, // Label color when focused
                            },
                            "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                    borderColor: COLORS.orange, // Default border color
                                },
                                "&:hover fieldset": {
                                    borderColor: COLORS.orange, // Border color on hover
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: COLORS.orange, // Border color when focused
                                },
                            },
                        }}
                    >
                        <InputLabel id="Saved Players">
                            Saved Players
                        </InputLabel>
                        <Select
                            label="Saved Players"
                            defaultValue=""
                            sx={{ textAlign: "left" }}
                        >
                            {(
                                Object.keys(savedPlayers) as Array<
                                    keyof typeof savedPlayers
                                >
                            ).flatMap((category, index, array) => [
                                // Category Header
                                <MenuItem
                                    key={`header-${category}`}
                                    disabled
                                    sx={{
                                        "&.Mui-disabled": {
                                            opacity: 1,
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: COLORS.orange,
                                        }}
                                    >
                                        {category
                                            .replace(
                                                "myAccounts",
                                                "My Accounts"
                                            )
                                            .replace("myFriends", "My Friends")
                                            .replace("following", "Following")}
                                    </Typography>
                                </MenuItem>,

                                // Players in the Category
                                ...(savedPlayers[category]?.length > 0
                                    ? savedPlayers[category].map(
                                          (player, playerIndex) => (
                                              <MenuItem
                                                  key={`player-${category}-${playerIndex}`}
                                                  onClick={() =>
                                                      handleSavedPlayerClick(
                                                          player.playerName,
                                                          player.platform
                                                      )
                                                  }
                                              >
                                                  <img
                                                      src={
                                                          platformLogos[
                                                              player.platform
                                                          ]
                                                      }
                                                      alt={`${
                                                          platformLogos[
                                                              player.platform
                                                          ]
                                                      } logo`}
                                                      style={{
                                                          width: "20px",
                                                          height: "20px",
                                                          marginRight: "8px",
                                                          objectFit: "contain",
                                                      }}
                                                  />
                                                  {player.playerName}
                                              </MenuItem>
                                          )
                                      )
                                    : [
                                          <MenuItem
                                              key={`no-players-${category}`}
                                              disabled
                                          >
                                              <Typography variant="body2">
                                                  No players in this category.
                                              </Typography>
                                          </MenuItem>,
                                      ]),

                                // Divider after each category except the last
                                index < array.length - 1 && (
                                    <Divider key={`divider-${category}`} />
                                ),
                            ])}
                        </Select>
                    </FormControl>
                    {loadingSavedPlayers && (
                        <CircularProgress
                            size={24}
                            sx={{ position: "absolute", top: 4, right: 16 }}
                        />
                    )}
                </Box>
            )}

            {/* Search Bar */}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }, // Stack on small screens, inline on larger
                    alignItems: "center",
                    justifyContent: "center",
                    gap: { xs: 2, sm: 1 }, // Add more spacing for smaller screens
                    width: "100%", // Ensure it adapts to screen size
                }}
            >
                <FormControl
                    sx={{
                        minWidth: "100px",
                        marginRight: { xs: 0, sm: 1 }, // Remove margin on small screens
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    <Select
                        value={platform}
                        onChange={(e) => setPlatform(e.target.value)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            "& img": {
                                width: "20px",
                                height: "20px",
                            },
                        }}
                    >
                        {Object.entries(platformLogos).map(([key, logo]) => (
                            <MenuItem key={key} value={key}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "8px",
                                    }}
                                >
                                    <img
                                        src={logo}
                                        alt={`${key} logo`}
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                            objectFit: "contain",
                                        }}
                                    />
                                    {key.toUpperCase()}
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Enter Player Name"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                    variant="outlined"
                    sx={{ marginRight: 1, width: "300px" }}
                />
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: COLORS.orange,
                        fontWeight: "bold",
                        ":hover": {
                            backgroundColor: COLORS.darkOrange,
                        },
                    }}
                    onClick={handleSearch}
                    disabled={!playerName.trim()}
                >
                    Search
                </Button>
            </Box>

            {/* Need Suggestions? */}
            <Typography variant="h6" marginBottom={-2}>
                Need search suggestions?
            </Typography>

            {/* Recommended Searches */}
            {loading ? (
                <CircularProgress /> // Show loading indicator
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                    }}
                >
                    {Object.entries(predefinedPlayers).map(
                        ([category, players]) => (
                            <Box key={category} sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle1" gutterBottom>
                                    {category}
                                </Typography>
                                <Box
                                    sx={{
                                        display: "flex",
                                        gap: 1,
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                    }}
                                >
                                    {players.map((player) => (
                                        <Button
                                            key={player}
                                            variant="contained"
                                            onClick={() =>
                                                handleRecommendedSearch(player)
                                            }
                                            sx={{
                                                textTransform: "none",
                                                backgroundColor:
                                                    categoryColors[category] ||
                                                    categoryColors["Default"],
                                                "&:hover": {
                                                    backgroundColor:
                                                        categoryColors[category]
                                                            ? `${categoryColors[category]}CC` // Slightly darker on hover
                                                            : `${categoryColors["Default"]}CC`,
                                                },
                                            }}
                                        >
                                            {teamDataMap[player]?.teamLogo && (
                                                <img
                                                    src={`${
                                                        process.env
                                                            .REACT_APP_API_URL
                                                    }/cached-images/${encodeURIComponent(
                                                        teamDataMap[player]
                                                            .teamLogo!.split(
                                                                "/"
                                                            )
                                                            .pop() || ""
                                                    )}`}
                                                    alt={`${
                                                        teamDataMap[player]
                                                            .teamName || "Team"
                                                    } Logo`}
                                                    style={{
                                                        width: "20px",
                                                        height: "20px",
                                                        objectFit: "contain",
                                                        marginRight: "8px",
                                                    }}
                                                />
                                            )}
                                            <Typography marginRight={0.5}>
                                                {player}{" "}
                                            </Typography>
                                            {teamDataMap[player]?.teamName && (
                                                <Typography>
                                                    (
                                                    {
                                                        teamDataMap[player]
                                                            .teamName
                                                    }
                                                    )
                                                </Typography>
                                            )}
                                        </Button>
                                    ))}
                                </Box>
                            </Box>
                        )
                    )}
                </Box>
            )}
        </Box>
    );
};

export default PlayerSearch;

import React from "react";
import { Box, Typography } from "@mui/material";

const Preferences: React.FC = () => {
    return (
        <Box sx={{ padding: 2, maxWidth: 1400, margin: "0 auto" }}>
            <Typography variant="h5" gutterBottom>
                Preferences
            </Typography>
        </Box>
    );
};

export default Preferences;

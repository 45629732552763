import React, { useState } from "react";
import {
    Box,
    Button,
    Typography,
    Modal,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Divider,
    Link,
} from "@mui/material";

export const predefinedPlayers: {
    "Pro Players": string[];
    "YouTubers/Streamers": string[];
} = {
    "Pro Players": ["TGLTN", "ADOUZ1E", "xmpl"],
    "YouTubers/Streamers": ["chocoTaco", "shroud", "WackyJacky101"],
};

interface CompareModalProps {
    open: boolean;
    onClose: () => void;
    onSelectPlayer: (player: string) => void;
}

const CompareModal: React.FC<CompareModalProps> = ({
    open,
    onClose,
    onSelectPlayer,
}) => {
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="compare-modal-title"
            aria-describedby="compare-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: { xs: "90%", sm: "60%", md: "40%" },
                    bgcolor: "background.paper",
                    border: "2px solid #000",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography
                    id="compare-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center" }}
                >
                    Compare your stats with popular players!
                </Typography>

                {Object.entries(predefinedPlayers).map(
                    ([category, players]) => (
                        <Box key={category} sx={{ mt: 2 }}>
                            <Typography variant="subtitle1">
                                {category}
                            </Typography>
                            <List>
                                {players.map((player) => (
                                    <ListItem key={player} disablePadding>
                                        <ListItemButton
                                            onClick={() =>
                                                onSelectPlayer(player)
                                            }
                                        >
                                            <ListItemText primary={player} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </List>
                            <Divider />
                        </Box>
                    )
                )}
                <Box
                    sx={{
                        mt: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    {/* Left-aligned feedback message */}
                    <Typography variant="body2">
                        Don’t see a player you want to compare with?{" "}
                        <Link
                            href="/feedback"
                            underline="hover"
                            color="primary"
                        >
                            Suggest adding more players to this template.
                        </Link>
                    </Typography>

                    {/* Right-aligned close button */}
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </Box>

                {/* Feedback Message */}
            </Box>
        </Modal>
    );
};

export default CompareModal;

import React, { useState } from "react";
import {
    TextField,
    Button,
    Box,
    Typography,
    InputAdornment,
    IconButton,
} from "@mui/material";
import axiosInstance from "api/axios";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { ErrorOutline, Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS } from "constants/colors";

export const ResetPassword: React.FC = () => {
    // Passwords
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    // Password visibility
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Errors
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const navigate = useNavigate();

    const validatePassword = (value: string) => {
        if (value.length < 8 || value.length > 128) {
            setPasswordError("Password must be between 8 and 128 characters.");
        } else {
            setPasswordError("");
        }
    };

    const validateConfirmPassword = (value: string) => {
        if (value !== password) {
            setConfirmPasswordError("Passwords do not match.");
        } else {
            setConfirmPasswordError("");
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessage("");
        setError("");

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axiosInstance.post(
                "/api/auth/reset-password",
                {
                    token,
                    password,
                }
            );
            if (response.status === 200) {
                setMessage("Password reset was successful.");
            }
        } catch (err: any) {
            setError(err.response?.data?.message || "Something went wrong.");
        }
    };

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                maxWidth: 600, // Set max width
                margin: "0 auto", // Center the content
                mt: 4,
                p: 3,
            }}
        >
            <Typography variant="h5" gutterBottom>
                Reset Password
            </Typography>
            <TextField
                label="New Password"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                value={password}
                type={showPassword ? "text" : "password"}
                error={!!passwordError}
                onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => setShowPassword((prev) => !prev)}
                                edge="end"
                            >
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
            <Typography
                variant="body2"
                sx={{
                    ml: 2,
                    mb: 1,
                    color: "text.secondary",
                    textAlign: "left",
                    fontSize: "0.8rem",
                }}
            >
                Password must be 8–128 characters long. For better security, use
                longer and more random combinations.
            </Typography>
            {passwordError && (
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        mt: 1,
                        width: "100%",
                        color: "error.main",
                        marginBottom: 0.5,
                    }}
                >
                    <ErrorOutline fontSize="small" />
                    <Typography variant="body2">{passwordError}</Typography>
                </Box>
            )}
            {/* Only show the first text field after a successful password reset */}
            {message != "Password reset was successful." && (
                <TextField
                    label="Confirm Password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    required
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        validateConfirmPassword(e.target.value);
                    }}
                    error={!!confirmPasswordError}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowConfirmPassword((prev) => !prev)
                                    }
                                    edge="end"
                                >
                                    {showConfirmPassword ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {confirmPasswordError && (
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        mt: 1,
                        width: "100%",
                        color: "error.main",
                        marginBottom: 0.5,
                    }}
                >
                    <ErrorOutline fontSize="small" />
                    <Typography variant="body2">
                        {confirmPasswordError}
                    </Typography>
                </Box>
            )}
            {message && <Typography color="success.main">{message}</Typography>}
            {error && <Typography color="error">{error}</Typography>}
            {message != "Password reset was successful." && (
                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        mt: 3,
                        backgroundColor: COLORS.orange,
                        fontWeight: "bold",
                        ":hover": {
                            backgroundColor: COLORS.darkOrange,
                        },
                    }}
                >
                    Reset Password
                </Button>
            )}
            {message == "Password reset was successful." && (
                <Button
                    type="button"
                    variant="contained"
                    sx={{
                        mt: 3,
                        backgroundColor: COLORS.orange,
                        fontWeight: "bold",
                        ":hover": {
                            backgroundColor: COLORS.darkOrange,
                        },
                    }}
                    onClick={() => navigate("/login")}
                >
                    Back to Login
                </Button>
            )}
        </Box>
    );
};

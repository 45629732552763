// src/components/IBRMapListing.tsx
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { IBRMapData } from "constants/IBRMapData";
import { COLORS } from "constants/colors";

const IBRMapListing: React.FC = () => {
    const { mapName } = useParams<{ mapName: string }>();

    // Safely access map data with proper typing
    const map = mapName ? IBRMapData[mapName] : undefined;

    if (!map) {
        return <Typography variant="h5">Map not found</Typography>;
    }

    console.log("Resolved map data:", map);

    return (
        <Box
            sx={{
                padding: "20px",
                maxWidth: {
                    xs: "100%",
                    sm: "80%",
                    md: "60%",
                },
                margin: "0 auto",
            }}
        >
            <Typography variant="h4" gutterBottom>
                {map.name} Locations
            </Typography>
            <Grid container spacing={3}>
                {map.locations.map((location) => (
                    <Grid item xs={12} sm={6} md={4} key={location.name}>
                        <Link
                            to={`/maps/ibr-crates/${mapName}/${location.name.toLowerCase()}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                        >
                            <Box
                                sx={{
                                    borderRadius: "8px",
                                    overflow: "hidden",
                                    boxShadow: 2,
                                    "&:hover": { boxShadow: 4 },
                                    backgroundColor: COLORS.orange,
                                    color: "black",
                                }}
                            >
                                <Box
                                    component="img"
                                    src={location.image}
                                    alt={`${location.name} Image Placeholder`}
                                    sx={{
                                        width: "100%", // Ensures the image spans the full width of its parent
                                        height: "auto", // Set a consistent height for all images
                                        objectFit: "cover", // Crops the image to fit the dimensions without distortion
                                    }}
                                />

                                <Typography
                                    variant="h6"
                                    textAlign="center"
                                    mb={1}
                                >
                                    {location.name}
                                </Typography>
                            </Box>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default IBRMapListing;

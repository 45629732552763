import React, { useEffect, useState } from "react";
import { TextField, Button, Box, Typography } from "@mui/material";
import axiosInstance from "api/axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "AuthContext";
import { ErrorOutline } from "@mui/icons-material";
import { COLORS } from "constants/colors";

export const Verify: React.FC = () => {
    // Needed for auto login
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [verificationCode, setVerificationCode] = useState("");
    const [codeError, setCodeError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [resendMessage, setResendMessage] = useState("");
    const navigate = useNavigate();
    const { login } = useAuth();

    useEffect(() => {
        const storedEmail = localStorage.getItem("email");
        const storedUsername = localStorage.getItem("username");
        const storedPassword = localStorage.getItem("password");

        if (storedEmail && storedUsername && storedPassword) {
            setEmail(storedEmail);
            setUsername(storedUsername);
            setPassword(storedPassword);
        } else {
            navigate("/register");
        }
    }, [navigate]);

    const handleVerify = async () => {
        if (verificationCode.length !== 6) {
            setCodeError("Verification code must be 6 digits long.");
            return;
        }

        setError("");
        setIsLoading(true);
        try {
            const response = await axiosInstance.post("/api/auth/verify-code", {
                email,
                code: verificationCode,
            });
            if (response.status === 200) {
                // Determine the correct value for username or email
                const loginUsername = username?.trim() ? username : email;

                // Automatically log the user in
                const loginResponse = await axiosInstance.post(
                    "/api/auth/login",
                    {
                        username: loginUsername,
                        password,
                    }
                );
                if (loginResponse.status === 200) {
                    login(loginResponse.data.token); // Use the login function from useAuth
                    localStorage.removeItem("email");
                    localStorage.removeItem("username");
                    localStorage.removeItem("password");
                    navigate("/account-settings");
                }
            }
        } catch (err) {
            setError("Verification failed. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleResendCode = async () => {
        setError("");
        setIsLoading(true);
        setResendMessage("");

        try {
            const response = await axiosInstance.post(
                "/api/auth/send-verification-code",
                { email }
            );
            if (response.status === 200) {
                setResendMessage("Verification code resent. Check your email.");
            }
        } catch {
            setError("Failed to resend code. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mt: 4,
                p: 3,
                maxWidth: 600,
                margin: "0 auto",
            }}
        >
            <Typography variant="h5" component="h1" gutterBottom>
                Verify Your Email
            </Typography>

            <Typography variant="body1" gutterBottom textAlign={"left"}>
                A verification code has been sent to your email address. Please
                enter it below to complete your registration.
            </Typography>

            <Typography
                variant="body1"
                component="div"
                gutterBottom
                textAlign={"left"}
            >
                <ul>
                    <li>
                        Email verification is required to activate your account.
                        If you do not verify your email, you will not be able to
                        log in.
                    </li>
                    <li>
                        If you did not receive the verification code, you can
                        request a new one by clicking the{" "}
                        <strong>"Resend Code"</strong> button. Note that this
                        will invalidate the previous code.
                    </li>
                    <li>
                        If you're unable to verify your email right now, you can
                        restart the sign-up process later using the same email
                        address.
                    </li>
                </ul>
            </Typography>

            <TextField
                label="Verification Code"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                value={verificationCode}
                onChange={(e) => {
                    setVerificationCode(e.target.value);
                    if (e.target.value.length !== 6) {
                        setCodeError(
                            "Verification code must be 6 digits long."
                        );
                    } else {
                        setCodeError("");
                    }
                }}
            />
            {codeError && (
                <Box
                    sx={{
                        display: "flex",
                        gap: 1,
                        mt: 1,
                        width: "100%",
                        color: "error.main",
                        marginBottom: 0.5,
                    }}
                >
                    <ErrorOutline fontSize="small" />
                    <Typography variant="body2">{codeError}</Typography>
                </Box>
            )}
            {error && (
                <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}
            {resendMessage && (
                <Typography variant="body2" color="success" sx={{ mt: 2 }}>
                    {resendMessage}
                </Typography>
            )}
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    mt: 2,
                }}
            >
                <Button
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        color: COLORS.orange,
                        border: "1px solid orange",
                        borderRadius: "8px",
                        px: 2,
                        ":hover": {
                            backgroundColor: "rgba(255, 165, 0, 0.1)",
                        },
                    }}
                    onClick={handleVerify}
                    disabled={isLoading}
                >
                    {isLoading ? "Verifying..." : "Verify"}
                </Button>
                <Button
                    onClick={handleResendCode}
                    disabled={isLoading}
                    sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        color: COLORS.silver,
                        border: "1px solid #c7c7c7",
                        borderRadius: "8px",
                        px: 2,
                        ":hover": {
                            backgroundColor: "rgba(199, 199, 199, 0.1)",
                        },
                    }}
                >
                    Resend Code
                </Button>
            </Box>
        </Box>
    );
};

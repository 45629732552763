import React, { useEffect } from "react";
import {
    Tabs,
    Tab,
    Box,
    Typography,
    Tooltip,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import PlayerProfile from "./PlayerProfile";
import RecentMatches from "./RecentMatches";
import LifetimeStats from "./lifetimeStats/LifetimeStats";
import SeasonStats from "./SeasonStats";
import MatchDetails from "./MatchDetails";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { COLORS } from "constants/colors";

const TAB_ROUTES = [
    "/player-stats/profile",
    "/player-stats/matches",
    "/player-stats/lifetime",
    "/player-stats/season",
];

const TAB_TOOLTIPS = [
    "View player information, including Survival Mastery, Weapon Mastery, and Clan details.",
    "Access match data from the past 14 days.",
    "Explore lifetime stats.",
    "Check detailed stats for the current or past seasons, similar to what's displayed in the PUBG Career > Stats section.",
];

const TAB_LABELS = [
    "Player Profile",
    "Match History",
    "Lifetime Stats",
    "Season Stats",
];

const PlayerStatsLandingPage: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const playerName = searchParams.get("name") || "Unknown Player";
    const platform = searchParams.get("platform") || "steam";

    const isMatchDetailsPage =
        location.pathname.startsWith("/player-stats/matches/") &&
        location.pathname.split("/").length === 4;

    const activeTab = TAB_ROUTES.findIndex((route) =>
        location.pathname.startsWith(route)
    );

    // Responsive design
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (activeTab === -1 && !isMatchDetailsPage) {
            navigate(
                `${TAB_ROUTES[0]}?platform=${encodeURIComponent(
                    platform
                )}&name=${encodeURIComponent(playerName)}`,
                { replace: true }
            );
        }
    }, [activeTab, isMatchDetailsPage, navigate, playerName, platform]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        navigate(
            `${TAB_ROUTES[newValue]}?platform=${encodeURIComponent(
                platform
            )}&name=${encodeURIComponent(playerName)}`
        );
    };

    return (
        <Box sx={{ width: "100%", padding: 2 }}>
            <Tabs
                value={
                    isMatchDetailsPage
                        ? 1
                        : activeTab !== -1
                        ? activeTab
                        : false
                }
                onChange={handleTabChange}
                variant="fullWidth"
                aria-label="Player Stats Tabs"
                sx={{
                    mt: -2,
                    "& .MuiTab-root": {
                        borderBottom: "1px solid #ccc", // Gray bottom border for inactive tabs
                        color: "gray", // Inactive tab text color
                    },
                    "& .Mui-selected": {
                        color: "orange !important", // Orange text color for selected tab
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: COLORS.orange, // Orange bottom border for selected tab
                    },
                }}
            >
                {TAB_ROUTES.map((route, index) => (
                    <Tooltip
                        key={route}
                        title={
                            <Typography sx={{ fontSize: "0.875rem" }}>
                                {TAB_TOOLTIPS[index]}
                            </Typography>
                        }
                        arrow
                    >
                        <Tab
                            label={
                                index === 1 && isMatchDetailsPage ? (
                                    <Box display="inline">
                                        {!isMobile && (
                                            <>
                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        color: "text.secondary",
                                                        fontSize: "0.875rem",
                                                    }}
                                                >
                                                    Match History
                                                </Typography>
                                                <ArrowRightAltIcon
                                                    sx={{
                                                        fontSize: "1rem",
                                                        color: "text.secondary",
                                                        marginBottom: "-3px",
                                                    }}
                                                />
                                            </>
                                        )}

                                        <Typography
                                            component="span"
                                            sx={{
                                                color: "primary.main",
                                                fontSize: "0.875rem",
                                            }}
                                        >
                                            Match Details
                                        </Typography>
                                    </Box>
                                ) : (
                                    TAB_LABELS[index]
                                )
                            }
                        />
                    </Tooltip>
                ))}
            </Tabs>

            <Box sx={{ marginTop: 2 }}>
                <Routes>
                    <Route path="profile" element={<PlayerProfile />} />
                    <Route path="matches" element={<RecentMatches />} />
                    <Route path="matches/:matchId" element={<MatchDetails />} />
                    <Route path="lifetime" element={<LifetimeStats />} />
                    <Route path="season" element={<SeasonStats />} />
                </Routes>
            </Box>
        </Box>
    );
};

export default PlayerStatsLandingPage;

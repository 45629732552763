export const lifetimeFieldLabels: { [key: string]: string } = {
    assists: "Assists",
    boosts: "Boosts Used",
    dBNOs: "Down But Not Out (DBNOs)",
    dailyKills: "Average Kills Per Day Played",
    dailyWins: "Average Wins Per Day Played",
    damageDealt: "Damage Dealt",
    days: "Days Played",
    headshotKills: "Headshot Kills",
    heals: "Heals Used",
    killPoints: "Kill Points",
    kills: "Kills",
    longestKill: "Longest Kill (m)",
    longestTimeSurvived: "Longest Time Survived (min)",
    losses: "Losses",
    maxKillStreaks: "Highest Kill Streak", // Not too sure what this is
    // mostSurvivalTime: "Most Survival Time (min)", // Duplicate of longestTimeSurvived
    // rankPoints: "Rank Points", // blank in API
    // rankPointsTitle: "Rank Points Title", // blank in API
    revives: "Revives",
    rideDistance: "Ride Distance (km)",
    roadKills: "Road Kills",
    roundMostKills: "Most Kills in a Match",
    roundsPlayed: "Matches Played",
    suicides: "Suicides",
    swimDistance: "Swim Distance (km)",
    teamKills: "Team Kills",
    timeSurvived: "Time Survived (hours)",
    top10s: "Top 10 Finishes",
    vehicleDestroys: "Vehicles Destroyed",
    walkDistance: "Walk Distance (km)",
    weaponsAcquired: "Weapons Acquired",
    weeklyKills: "Average Kills Per Week Played",
    weeklyWins: "Average Wins Per Week Played",
    // winPoints: "Win Points", // blank in API
    wins: "Wins",
};

export const mapNameMapping: { [key: string]: string } = {
    Desert_Main: "Miramar",
    Tiger_Main: "Taego",
    DihorOtok_Main: "Vikendi",
    Baltic_Main: "Erangel",
    Neon_Main: "Rondo",
    Kiki_Main: "Deston",
    Savage_Main: "Sanhok",
    Range_Main: "Camp Jackal",
};

// used in the RecentMatches and MatchDetails components

import React from "react";
import {
    Card,
    CardContent,
    Typography,
    LinearProgress,
    Box,
    CardActions,
    Button,
} from "@mui/material";
import dinner from "assets/dinner.png";
import trophy from "assets/trophy.png";
import { mapNameMapping } from "utils/mapNameMapping";
import { Match } from "types/match";
import { formatGameMode } from "utils/formatGameMode";
import { useNavigate } from "react-router-dom";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { COLORS } from "constants/colors";

interface MatchCardProps {
    match: Match;
    accountId: string | null;
    viewType: "compact" | "detailed";
    showViewDetailsButton?: boolean;
    platform: string;
    playerName: string;
}

const MatchCard: React.FC<MatchCardProps> = ({
    match,
    accountId,
    viewType,
    platform,
    playerName,
    showViewDetailsButton = true,
}) => {
    const isWinner = match.playerWinPlace === 1;
    const isSurvivor =
        match.matchType !== "tdm" &&
        match.matchType !== "training" &&
        match.matchType !== "trainingroom" &&
        match.playerSurvivalTime >= match.duration - 15;

    const navigate = useNavigate();

    // Calculate dynamic minHeight
    const minHeight =
        viewType === "compact"
            ? "164px"
            : showViewDetailsButton
            ? "274px" // Shorter height when the button is visible
            : "230px"; // Default detailed view height without button

    return (
        <Card
            sx={{
                position: "relative",
                minHeight,
                marginBottom: "16px",
            }}
        >
            <CardContent>
                {/* Trophy or Chicken Dinner Icon */}
                {isWinner && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 8,
                            left: 8,
                            zIndex: 1,
                            padding: 1,
                        }}
                    >
                        <img
                            src={
                                ["ibr", "tdm"].includes(match.gameMode)
                                    ? trophy
                                    : dinner
                            }
                            alt={
                                ["ibr", "tdm"].includes(match.gameMode)
                                    ? "Trophy Icon"
                                    : "Chicken Dinner Icon"
                            }
                            style={{
                                width: 40,
                                height: 40,
                            }}
                        />
                    </Box>
                )}
                {isSurvivor && (
                    <Box
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8, // Place on the right side
                            zIndex: 1,
                            padding: 1,
                        }}
                    >
                        <VerifiedUserIcon
                            sx={{
                                fontSize: 40, // Adjust size
                                color: COLORS.green, // Green color to signify survival
                            }}
                        />
                    </Box>
                )}
                <Typography variant="h6">
                    Map: {mapNameMapping[match.mapName] || match.mapName}
                </Typography>
                <Typography>
                    Game Mode: {formatGameMode(match.gameMode, match.matchType)}
                </Typography>

                {match.gameMode !== "tdm" &&
                match.matchType !== "training" &&
                match.matchType !== "trainingroom" ? (
                    <Typography>
                        Place: #{match.playerWinPlace} / {match.rosters.length}
                    </Typography>
                ) : null}

                {viewType === "detailed" && (
                    <>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            mt={2}
                            textAlign="left"
                        >
                            <Box>
                                <Typography>
                                    Kills: {match.playerKills}
                                </Typography>
                                <Typography>
                                    Damage: {match.playerDamage.toFixed(0)}
                                </Typography>
                            </Box>
                            {accountId && (
                                <Box>
                                    <Typography>
                                        Team Kills: {match.playerRosterKills}
                                    </Typography>
                                    <Typography>
                                        Team Damage:{" "}
                                        {match.playerRosterDamage.toFixed(0)}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                            <Typography variant="body2">
                                Survival Time:{" "}
                                {Math.floor(match.playerSurvivalTime / 60)}m{" "}
                                {match.playerSurvivalTime % 60}s /{" "}
                                {Math.floor(match.duration / 60)}m{" "}
                                {match.duration % 60}s
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={
                                    (match.playerSurvivalTime /
                                        match.duration) *
                                    100
                                }
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    backgroundColor: "#ddd",
                                    "& .MuiLinearProgress-bar": {
                                        backgroundColor: COLORS.green,
                                    },
                                }}
                            />
                        </Box>
                    </>
                )}
            </CardContent>
            {showViewDetailsButton && (
                <CardActions>
                    <Button
                        onClick={() => {
                            if (match.matchId) {
                                navigate(
                                    `/player-stats/matches/${
                                        match.matchId
                                    }?platform=${encodeURIComponent(
                                        platform
                                    )}&name=${encodeURIComponent(playerName)}`
                                );
                            } else {
                                console.error("Match ID is undefined:", match);
                            }
                        }}
                    >
                        View Details
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default MatchCard;

import React from "react";
import { Box, Typography, Button, useTheme, Divider } from "@mui/material";

// Icons
import PayPalIcon from "@mui/icons-material/Payment";
import CoffeeIcon from "@mui/icons-material/Coffee";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import { COLORS } from "constants/colors";

const SupportUsPage: React.FC = () => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                padding: { xs: "16px", md: "40px" },
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: "80vh",
                maxWidth: "1400px",
                margin: "0 auto",
                borderRadius: "8px",
                textAlign: "left",
            }}
        >
            <Typography variant="h4" gutterBottom>
                Support PUBG Meta
            </Typography>
            <Typography variant="body1" paragraph>
                If you enjoy using PUBG Meta and would like to support our
                efforts to keep the platform running and improve its features,
                consider making a donation.
            </Typography>
            <Divider></Divider>
            <Typography mt={2} variant="body1" paragraph>
                At PUBG Meta, we prioritize user experience and satisfaction,
                which is why we choose not to display ads on our website. Ads
                can greatly diminish the quality of your experience, and we
                believe in providing a clean, distraction-free platform.
            </Typography>
            <Typography variant="body1" paragraph>
                In fact, we also encourage you to install ad blockers on your
                desktop and mobile devices to enhance your browsing experience
                across the web. Some popular ad blocker options include:
                <ul>
                    <li>
                        <a
                            href="https://adblockplus.org/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007BFF" }}
                        >
                            Adblock Plus
                        </a>{" "}
                        (Desktop & Mobile)
                    </li>
                    <li>
                        <a
                            href="https://getadblock.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007BFF" }}
                        >
                            AdBlock
                        </a>{" "}
                        (Desktop & Mobile)
                    </li>
                    <li>
                        <a
                            href="https://ublockorigin.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#007BFF" }}
                        >
                            uBlock Origin
                        </a>{" "}
                        (Desktop)
                    </li>
                </ul>
            </Typography>
            <Divider></Divider>
            <Typography mt={2} variant="body1" paragraph>
                Instead, we take an organic approach to monetization through
                voluntary support from our community. Every contribution helps
                us cover hosting costs, develop new features, and continuously
                improve the platform for our users.
            </Typography>
            {/* <Typography variant="h5" gutterBottom>
                What do I get?
            </Typography>
            <Typography variant="body1" paragraph>
                - Your profile will display a prominent custom icon of your
                choosing (for example, a check mark, a level 3 helmet, a ghillie
                suit, etc.).
            </Typography>
            <Typography variant="body1" paragraph>
                - You will be added to the supporters list below with the option
                to show your PUBG name, alias, or remain anonymous.
            </Typography>
            <Typography variant="body1" paragraph>
                - The number of donations or total amount of donations can also
                be made public on the supporters table if you wish.
            </Typography> */}
            {/* Donation Buttons */}
            <Box sx={{ display: "flex" }}>
                <Button
                    variant="contained"
                    sx={{
                        margin: "10px",
                        backgroundColor: COLORS.blue,
                        textTransform: "none",
                        "&:hover": {
                            backgroundColor: "#1976D2", // Darker shade of blue for hover
                        },
                    }}
                    href="https://www.paypal.com/ncp/payment/M7VU9CTDW29VN" // Replace with your PayPal Donate link
                    target="_blank"
                    startIcon={<PayPalIcon />}
                >
                    Donate via PayPal
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        textTransform: "none",
                        margin: "10px",
                        backgroundColor: COLORS.green,
                        "&:hover": {
                            backgroundColor: "#388E3C", // Darker shade of green for hover
                        },
                    }}
                    href="https://www.buymeacoffee.com/pubgmeta" // Replace with your Buy Me a Coffee link
                    target="_blank"
                    startIcon={<CoffeeIcon />}
                >
                    Donate via Buy Me a Coffee
                </Button>
            </Box>
            <Box sx={{ display: "flex", marginBottom: 4 }}>
                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        textTransform: "none",
                        margin: "10px",
                        backgroundColor: "#FF4500", // Bright orange for Patreon
                        "&:hover": {
                            backgroundColor: "#E63900", // Darker shade of orange for hover
                        },
                    }}
                    disabled // Disable the button to indicate it's coming soon
                    startIcon={<AttachMoneyIcon />}
                >
                    Patreon (Coming Soon)
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                        textTransform: "none",
                        margin: "10px",
                        backgroundColor: "#FF5E5B", // Light red for Ko-fi
                        "&:hover": {
                            backgroundColor: "#E0524E", // Darker shade of red for hover
                        },
                    }}
                    disabled // Disable the button to indicate it's coming soon
                    startIcon={<LocalCafeIcon />}
                >
                    Ko-fi (Coming Soon)
                </Button>
            </Box>

            {/* <Typography variant="h5" gutterBottom>
                Our Supporters
            </Typography>
            <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Name</strong>
                            </TableCell>
                            <TableCell>
                                <strong># of Donations</strong>
                            </TableCell>
                            <TableCell>
                                <strong>$ of Donations</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                No supporters yet. Be the first to support us!
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography variant="h5" gutterBottom>
                Patreon Supporters
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <strong>Name</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Tier</strong>
                            </TableCell>
                            <TableCell>
                                <strong>Since</strong>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={3} align="center">
                                No Patreon supporters yet. Join our Patreon!
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> */}
        </Box>
    );
};

export default SupportUsPage;

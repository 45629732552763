export const patches = [
    {
        name: "Patch 33.1",
        date: "2024.12.02",
        link: "https://pubg.com/en/news/7988",
    },
    {
        name: "Patch 32.2",
        date: "2024.11.04",
        link: "https://pubg.com/en/news/7875",
        pdf: "/patches/322.pdf",
        aiSummary: `
            - **New Arcade Mode: Hot Drop**: This mode delivers intense, fast-paced gameplay by confining players to small zones on Erangel, Miramar, Vikendi, or Taego. Blue Zones shrink rapidly, pushing for quick looting and relentless combat.

            - **New Vehicle: Inflatable Boat**: A stealthy watercraft powered by oars, seating up to four players. It allows for quieter water navigation, achieving speeds of up to 50 km/h with coordinated rowing.
        `,
    },
    {
        name: "Patch 32.1",
        date: "2024.10.09",
        link: "https://pubg.com/en/news/7810",
    },
    {
        name: "Patch 31.2",
        date: "2024.09.03",
        link: "https://pubg.com/en/news/7624",
    },
    {
        name: "Patch 31.1",
        date: "2024.08.06",
        link: "https://pubg.com/en/news/7582",
    },
    {
        name: "Patch 30.2",
        date: "2024.07.09",
        link: "https://pubg.com/en/news/7494",
    },
    {
        name: "Patch 30.1",
        date: "2024.06.11",
        link: "https://pubg.com/en/news/7424",
    },
    {
        name: "Patch 29.2",
        date: "2024.05.13",
        link: "https://pubg.com/en/news/7172",
    },
    {
        name: "Patch 29.1",
        date: "2024.04.08",
        link: "https://pubg.com/en/news/7172",
    },
    {
        name: "Patch 28.2",
        date: "2024.03.12",
        link: "https://pubg.com/en/news/7038",
    },
    {
        name: "Patch 28.1",
        date: "2024.02.06",
        link: "https://pubg.com/en/news/7038",
    },
    {
        name: "Patch 27.2",
        date: "2024.01.09",
        link: "https://pubg.com/en/news/6996",
    },
    {
        name: "Patch 27.1",
        date: "2023.12.12",
        link: "https://pubg.com/en/news/6939",
    },
    {
        name: "Patch 26.2",
        date: "2023.11.08",
        link: "https://pubg.com/en/news/6833",
    },
    {
        name: "Patch 26.1",
        date: "2023.10.11",
        link: "https://pubg.com/en/news/6717",
    },
    {
        name: "Patch 25.2",
        date: "2023.09.06",
        link: "https://pubg.com/en/news/6616",
    },
    {
        name: "Patch 25.1",
        date: "2023.08.09",
        link: "https://pubg.com/en/news/6548",
    },
    {
        name: "Patch 24.2",
        date: "2023.07.04",
        link: "https://pubg.com/en/news/6453",
    },
    {
        name: "Patch 24.1",
        date: "2023.06.07",
        link: "https://pubg.com/en/news/6359",
    },
    {
        name: "Patch 23.2",
        date: "2023.05.10",
        link: "https://pubg.com/en/news/6292",
    },
    {
        name: "Patch 23.1",
        date: "2023.04.12",
        link: "https://pubg.com/en/news/5040",
    },
    {
        name: "Patch 22.2",
        date: "2023.03.07",
        link: "https://pubg.com/en/news/4555",
    },
    {
        name: "Patch 22.1",
        date: "2023.02.08",
        link: "https://pubg.com/en/news/4553",
    },
    {
        name: "Patch 21.2",
        date: "2023.01.11",
        link: "https://pubg.com/en/news/4552",
    },
    {
        name: "Patch 21.1",
        date: "2022.12.14",
        link: "https://pubg.com/en/news/1721",
    },
    {
        name: "Patch 20.2",
        date: "2022.11.09",
        link: "https://pubg.com/en/news/1724",
    },
    {
        name: "Patch 20.1",
        date: "2022.10.12",
        link: "https://pubg.com/en/news/1730",
    },
    {
        name: "Patch 19.2",
        date: "2022.09.07",
        link: "https://pubg.com/en/news/1734",
    },
    {
        name: "Patch 19.1",
        date: "2022.08.10",
        link: "https://pubg.com/en/news/1738",
    },
    {
        name: "Patch 18.2",
        date: "2022.07.06",
        link: "https://pubg.com/en/news/1741",
    },
    {
        name: "Patch 18.1",
        date: "2022.06.08",
        link: "https://pubg.com/en/news/1742",
    },
    {
        name: "Patch 17.2",
        date: "2022.05.11",
        link: "https://pubg.com/en/news/1713",
    },
    {
        name: "Patch 17.1",
        date: "2022.04.13",
        link: "https://pubg.com/en/news/1715",
    },
    {
        name: "Patch 16.2",
        date: "2022.03.09",
        link: "https://pubg.com/en/news/1717",
    },
    {
        name: "Patch 16.1",
        date: "2022.02.09",
        link: "https://pubg.com/en/news/1719",
    },
    {
        name: "Patch 15.2",
        date: "2022.01.12",
        link: "https://pubg.com/en/news/1725",
    },
    {
        name: "Patch 15.1",
        date: "2021.12.08",
        link: "https://pubg.com/en/news/1727",
    },
    {
        name: "Patch 14.2",
        date: "2021.11.10",
        link: "https://pubg.com/en/news/1735",
    },
    {
        name: "Patch 14.1",
        date: "2021.10.06",
        link: "https://pubg.com/en/news/1714",
    },
    // {
    //     name: "Patch 13.2",
    //     date: "2021.09.08",
    //     link: "https://pubg.com/en/news/5199",
    // },
    {
        name: "Patch 13.1",
        date: "2021.08.04",
        link: "https://pubg.com/en/news/1729",
    },
    {
        name: "Patch 12.2",
        date: "2021.07.07",
        link: "https://pubg.com/en/news/1740",
    },
    {
        name: "Patch 12.1",
        date: "2021.06.09",
        link: "https://pubg.com/en/news/1744",
    },
    {
        name: "Patch 11.2",
        date: "2021.05.12",
        link: "https://pubg.com/en/news/1710",
    },
    // {
    //     name: "Patch 11.1",
    //     date: "2021.04.07",
    //     link: "https://pubg.com/en/news/4914",
    // },
    // {
    //     name: "Patch 10.3",
    //     date: "2021.03.03",
    //     link: "https://pubg.com/en/news/4857",
    // },
    // {
    //     name: "Patch 10.2",
    //     date: "2021.02.10",
    //     link: "https://pubg.com/en/news/4800",
    // },
    // {
    //     name: "Patch 10.1",
    //     date: "2021.01.13",
    //     link: "https://pubg.com/en/news/4743",
    // },
    // {
    //     name: "Patch 9.2",
    //     date: "2020.11.11",
    //     link: "https://pubg.com/en/news/4686",
    // },
    // {
    //     name: "Patch 9.1",
    //     date: "2020.10.21",
    //     link: "https://pubg.com/en/news/4630",
    // },
    // {
    //     name: "Patch 8.3",
    //     date: "2020.09.16",
    //     link: "https://pubg.com/en/news/4573",
    // },
    // {
    //     name: "Patch 8.2",
    //     date: "2020.08.19",
    //     link: "https://pubg.com/en/news/4516",
    // },
    // {
    //     name: "Patch 8.1",
    //     date: "2020.07.15",
    //     link: "https://pubg.com/en/news/4459",
    // },
    // {
    //     name: "Patch 7.3",
    //     date: "2020.06.10",
    //     link: "https://pubg.com/en/news/4402",
    // },
    // {
    //     name: "Patch 7.2",
    //     date: "2020.05.13",
    //     link: "https://pubg.com/en/news/4345",
    // },
    // {
    //     name: "Patch 7.1",
    //     date: "2020.04.15",
    //     link: "https://pubg.com/en/news/4288",
    // },
    // {
    //     name: "Patch 6.3",
    //     date: "2020.03.11",
    //     link: "https://pubg.com/en/news/4231",
    // },
    // {
    //     name: "Patch 6.2",
    //     date: "2020.02.19",
    //     link: "https://pubg.com/en/news/4174",
    // },
    // {
    //     name: "Patch 6.1",
    //     date: "2020.01.15",
    //     link: "https://pubg.com/en/news/4117",
    // },
];

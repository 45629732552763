// utils.ts
export interface Roster {
    id: string;
    rank: number;
    teamId: number;
    won: boolean;
    participants: string[];
    totalKills?: number;
    totalDamage?: number;
}

export interface Participant {
    id: string;
    name: string;
    kills: number;
    damageDealt: number;
    walkDistance: number;
    winPlace: number;
    timeSurvived: number;
    playerId: string;
}

export const computeRosterStats = (
    rosters: Roster[],
    participants: Participant[]
): Roster[] => {
    return rosters.map((roster) => {
        const rosterParticipants = roster.participants.map((id) =>
            participants.find((p) => p.id === id)
        );

        const totalKills = rosterParticipants.reduce(
            (sum, p) => sum + (p?.kills || 0),
            0
        );
        const totalDamage = rosterParticipants.reduce(
            (sum, p) => sum + (p?.damageDealt || 0),
            0
        );

        return {
            ...roster,
            totalKills,
            totalDamage,
        };
    });
};

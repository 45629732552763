import React from "react";
import { Route, Routes } from "react-router-dom";
import IBRMapListing from "./ibr/IBRPage2";
import IBRLocationDetails from "./ibr/IBRPage3";
import { Box, Typography } from "@mui/material";
import IBRMapSection from "./ibr/IBRPage1";

const IBRCrates: React.FC = () => (
    <Routes>
        {/* Default route for /maps/ibr-crates */}
        <Route
            path="/"
            element={
                <Box sx={{ textAlign: "center", padding: "20px" }}>
                    <Typography variant="h4" gutterBottom>
                        Select a Map
                    </Typography>
                    <IBRMapSection />
                </Box>
            }
        />
        {/* Route for specific map (e.g., /maps/ibr-crates/erangel) */}
        <Route path="/:mapName" element={<IBRMapListing />} />
        {/* Route for specific location within a map */}
        <Route
            path="/:mapName/:locationName"
            element={<IBRLocationDetails />}
        />
    </Routes>
);

export default IBRCrates;

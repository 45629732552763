export const faqData = [
    {
        id: "faq4",
        question: "What makes us different from other PUBG stats websites?",
        answer: "We are the ultimate PUBG companion, meant to be used side by side while playing the game. Our site helps you check maps for secret room locations and review stats as you play.",
    },

    {
        id: "faq1",
        question: "How many people work on this?",
        answer: "Just myself, a full-stack software engineer passionate about web apps, working on this as a personal project.",
    },
    {
        id: "faq2",
        question: "Why did you work on this?",
        answer: "I wanted to fill the gap on some features that other leading PUBG-related websites are missing.",
    },
    {
        id: "faq3",
        question: "Will there be updates and improvements?",
        answer: "Yes, this is an iterative process. I always aim to improve the site and take user feedback into account to make it better.",
    },
];

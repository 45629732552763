import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { loadFromCache, saveToCache } from "api/cacheUtils";
import ReactGA from "react-ga4";
import { formatSeasonName, getSeasonStatus } from "constants/seasonStartDates";

// Helper function to format data point names
const formatDataPointName = (key: string): string => {
    return key
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};

// Helper function to format game mode names
const formatGameModeName = (gameMode: string): string => {
    return gameMode.includes("-fpp")
        ? gameMode.replace("-fpp", " FPP").toUpperCase()
        : `${gameMode.toUpperCase()} TPP`;
};

const filterSeasons = (seasons: any[], platform: string): any[] => {
    return seasons.filter((season) => {
        const isConsoleSeason = season.id.includes("console");
        const is2017Season = season.id.includes("2017");
        const isLegacySeason =
            !season.id.includes("pc") && !season.id.includes("console");

        return (
            !(isConsoleSeason && platform === "steam") &&
            !is2017Season &&
            !isLegacySeason
        );
    });
};

const SeasonStats: React.FC = () => {
    const [accountId, setAccountId] = useState<string | null>(null);
    const [seasons, setSeasons] = useState<any[]>([]);
    const [currentSeasonId, setCurrentSeasonId] = useState<string | null>(null);
    const [selectedSeasonId, setSelectedSeasonId] = useState<string | null>(
        null
    );
    const [seasonStats, setSeasonStats] = useState<any | null>(null);
    const [selectedStatType, setSelectedStatType] = useState<
        "normal" | "ranked"
    >("normal");
    const [selectedGameMode, setSelectedGameMode] =
        useState<string>("squad-fpp");
    const [availableGameModes, setAvailableGameModes] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const platform =
        new URLSearchParams(window.location.search).get("platform") || "steam";
    const playerName =
        new URLSearchParams(window.location.search).get("name") ||
        "Unknown Player";

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/season",
            title: "Season Stats Page",
        });
    }, []);

    // Step 1: Fetch the player's account ID
    useEffect(() => {
        const fetchAccountId = async () => {
            setLoading(true);
            setError(null);

            const cacheKey = `account-${platform}-${playerName}`;
            const cachedAccountId = loadFromCache(cacheKey);

            if (cachedAccountId) {
                setAccountId(cachedAccountId);
                setLoading(false);
                return;
            }

            try {
                const accountResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
                );

                if (!accountResponse.ok) {
                    throw new Error(
                        `Failed to fetch player info for ${playerName}.`
                    );
                }

                const accountData = await accountResponse.json();
                setAccountId(accountData.accountId);
                saveToCache(cacheKey, accountData.accountId);
            } catch (err) {
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        if (playerName && platform) {
            fetchAccountId();
        }
    }, [platform, playerName]);

    // Step 2: Fetch seasons
    useEffect(() => {
        const fetchSeasons = async () => {
            setLoading(true);
            setError(null);

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/seasons/${platform}`
                );
                if (!response.ok) {
                    throw new Error("Failed to fetch seasons");
                }
                const data = await response.json();
                const filteredSeasons = filterSeasons(data.data, platform);
                setSeasons(filteredSeasons);

                const currentSeason = filteredSeasons.find(
                    (season: any) => season.attributes.isCurrentSeason
                );
                if (currentSeason) {
                    setCurrentSeasonId(currentSeason.id);
                    setSelectedSeasonId(currentSeason.id);
                }
            } catch (err) {
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasons();
    }, [platform]);

    // Step 3: Fetch season stats
    useEffect(() => {
        const fetchSeasonStats = async () => {
            if (!selectedSeasonId || !accountId || !selectedStatType) return;

            // Check if ranked stats are supported for this season
            const isRankedAvailable =
                platform !== "steam" ||
                selectedSeasonId >= "division.bro.official.pc-2018-07";

            // Prevent fetch if Ranked is selected but not available
            if (selectedStatType === "ranked" && !isRankedAvailable) {
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const url = `${
                    process.env.REACT_APP_API_URL
                }/api/players/${platform}/${accountId}/seasons/${selectedSeasonId}?ranked=${
                    selectedStatType === "ranked"
                }`;

                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error("Failed to fetch season stats");
                }

                const data = await response.json();
                if (selectedStatType === "ranked") {
                    setSeasonStats(data.data.attributes.rankedGameModeStats);
                    setAvailableGameModes(
                        Object.keys(data.data.attributes.rankedGameModeStats)
                    );
                } else {
                    setSeasonStats(data.data.attributes.gameModeStats);
                    setAvailableGameModes(
                        Object.keys(data.data.attributes.gameModeStats)
                    );
                }
            } catch (err) {
                setError((err as Error).message);
            } finally {
                setLoading(false);
            }
        };

        fetchSeasonStats();
    }, [selectedSeasonId, platform, accountId, selectedStatType]);

    useEffect(() => {
        if (selectedSeasonId) {
            const isRankedAvailable =
                platform !== "steam" ||
                selectedSeasonId >= "division.bro.official.pc-2018-07";

            // Automatically switch to normal if Ranked is unavailable
            if (!isRankedAvailable && selectedStatType === "ranked") {
                setSelectedStatType("normal");
            }
        }
    }, [selectedSeasonId, platform, selectedStatType]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );
    }

    if (!accountId || !selectedSeasonId) {
        return (
            <Typography color="error" variant="body1">
                Missing account or season information. Please try again.
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: 1400,
                margin: "0 auto",
                display: "flex",
                flexDirection: "column",
                gap: 3,
            }}
        >
            <Typography variant="h4" gutterBottom>
                Season Stats for {playerName}
            </Typography>

            {/* Dropdown for Seasons */}
            <FormControl fullWidth>
                <InputLabel>Season</InputLabel>
                <Select
                    value={selectedSeasonId || ""}
                    onChange={(e) => setSelectedSeasonId(e.target.value)}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: "400px", // Limit height to 200px
                                overflowY: "auto", // Enable vertical scrolling
                            },
                        },
                    }}
                >
                    {seasons.map((season) => (
                        <MenuItem key={season.id} value={season.id}>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body1">
                                    {formatSeasonName(season.id, platform)}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    sx={{ fontStyle: "italic" }}
                                >
                                    {getSeasonStatus(
                                        season,
                                        currentSeasonId || ""
                                    )}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Box
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                    gap: 2,
                    alignItems: "flex-start",
                }}
            >
                {/* Buttons for Normal and Ranked */}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        padding: 2,
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        width: { xs: "100%", md: "20%", sm: "20%" },
                        justifyContent: {
                            xs: "center",
                            md: "left",
                            sm: "left",
                        }, // Center the buttons inside the box
                    }}
                >
                    <Button
                        variant={
                            selectedStatType === "normal"
                                ? "contained"
                                : "outlined"
                        }
                        onClick={() => setSelectedStatType("normal")}
                    >
                        Normal
                    </Button>
                    <Button
                        variant={
                            selectedStatType === "ranked"
                                ? "contained"
                                : "outlined"
                        }
                        onClick={() => setSelectedStatType("ranked")}
                        disabled={
                            platform === "steam" &&
                            selectedSeasonId <
                                "division.bro.official.pc-2018-07"
                        }
                    >
                        Ranked
                    </Button>
                </Box>

                {/* Game Mode Buttons */}
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            xs: "1fr 1fr",
                            md: "repeat(6, 1fr)",
                        }, // 2 per row on mobile, 4 per row on desktop
                        gap: 2,
                        padding: 2,
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        width: "100%",
                    }}
                >
                    {[
                        "solo",
                        "solo-fpp",
                        "duo",
                        "duo-fpp",
                        "squad",
                        "squad-fpp",
                    ].map((gameMode) => (
                        <Button
                            key={gameMode}
                            variant={
                                selectedGameMode === gameMode
                                    ? "contained"
                                    : "outlined"
                            }
                            onClick={() => setSelectedGameMode(gameMode)}
                            disabled={!availableGameModes.includes(gameMode)}
                        >
                            {formatGameModeName(gameMode)}
                        </Button>
                    ))}
                </Box>
            </Box>

            {/* Table for Season Stats */}
            {seasonStats && seasonStats[selectedGameMode] ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            {Object.entries(seasonStats[selectedGameMode]).map(
                                ([statKey, statValue]) => (
                                    <TableRow key={statKey}>
                                        <TableCell>
                                            {formatDataPointName(statKey)}
                                        </TableCell>
                                        <TableCell>
                                            {typeof statValue === "number"
                                                ? Math.round(
                                                      statValue
                                                  ).toLocaleString() // Format as locale string without decimals
                                                : String(statValue)}
                                        </TableCell>
                                    </TableRow>
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography>No stats available for this game mode.</Typography>
            )}
        </Box>
    );
};

export default SeasonStats;

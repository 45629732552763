import { ImageItem } from "../types/weaponItem";

export const saveTierList = async (
    tierList: { [key: string]: ImageItem[] },
    name: string,
    isPublic: boolean,
    description?: string,
    showUsername?: boolean
) => {
    try {
        // Prepare the tier list for backend
        const tierListForBackend: { [key: string]: number[] } = {};
        for (const tier in tierList) {
            tierListForBackend[tier] = tierList[tier].map((item) => item.id);
        }

        // Build the request payload
        const payload: Record<string, any> = {
            tierList: tierListForBackend,
            name,
            public: isPublic,
        };

        if (description) {
            payload.description = description; // Include description if provided
        }

        if (showUsername) {
            payload.showUsername = showUsername; // Include showUsername if provided
        }

        // Retrieve auth token from localStorage
        const authToken = localStorage.getItem("authToken");

        console.log("Auth token:", authToken);

        // Send the request to the backend
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/tier-list`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authToken ? `Bearer ${authToken}` : "", // Include token if available
                },
                body: JSON.stringify(payload),
            }
        );

        if (response.ok) {
            const data = await response.json();
            const shareableLink = `${window.location.origin}/tier-lists/maker/${data.id}`;
            navigator.clipboard.writeText(shareableLink);
            alert("Link copied to clipboard!");
        } else {
            const errorMessage = await response.text();
            alert(`Failed to save tier list. Error: ${errorMessage}`);
        }
    } catch (error) {
        console.error("Error saving tier list:", error);
        alert("An unexpected error occurred while saving the tier list.");
    }
};

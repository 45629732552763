import React, { useEffect, useState } from "react";
import {
    Typography,
    Box,
    CircularProgress,
    Grid,
    Button,
    TableCell,
    TableRow,
    Table,
    TableContainer,
    TableBody,
    Paper,
    TableHead,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { computeRosterStats, Participant } from "utils/rosterStats";

// Icons
import { loadFromCache, saveToCache } from "api/cacheUtils";
import { Match } from "types/match";
import MatchCard from "components/MatchCard";

// GA
import ReactGA from "react-ga4";
import { COLORS } from "constants/colors";

// Top Data Table Stats Calculation
const calculateSummaryStats = (matches: Match[], excludeModes: string[]) => {
    const filteredMatches = matches.filter(
        (match) =>
            match.matchType !== "training" &&
            !excludeModes.includes(match.gameMode)
    );

    const totalWins = filteredMatches.reduce(
        (total, match) => (match.playerWinPlace === 1 ? total + 1 : total),
        0
    );

    const totalKills = filteredMatches.reduce(
        (total, match) => total + (match.playerKills || 0),
        0
    );
    const totalDamage = filteredMatches.reduce(
        (total, match) => total + (match.playerDamage || 0),
        0
    );
    const totalSurvivalTimeInMinutes = filteredMatches.reduce(
        (total, match) => total + (match.playerSurvivalTime || 0),
        0
    );
    const totalMatches = filteredMatches.length;

    return {
        // Wins
        totalWins,
        winsPerDay: (totalWins / 14).toFixed(2),
        // Kills
        totalKills,
        killsPerMatch: totalMatches
            ? (totalKills / totalMatches).toFixed(2)
            : "0.00",
        killsPerDay: (totalKills / 14).toFixed(2),
        // Damage
        totalDamage: totalDamage.toFixed(0),
        damagePerMatch: totalMatches
            ? (totalDamage / totalMatches).toFixed(0)
            : "0.00",
        damagePerDay: (totalDamage / 14).toFixed(0),
        // Survival Time
        totalSurvivalTimeInHours: Math.floor(
            totalSurvivalTimeInMinutes / 60 / 60
        ),
        averageDailySurvivalTimeInHours: Math.floor(
            totalSurvivalTimeInMinutes / 60 / 60 / 14
        ),
        averagePerMatchSurvivalTimeInMinutes: Math.floor(
            totalSurvivalTimeInMinutes / 60 / totalMatches
        ),
        // Matches
        totalMatches,
        averageMatchesPerDay: (totalMatches / 14).toFixed(2),
    };
};

const RecentMatches: React.FC = () => {
    const [searchParams] = useSearchParams();
    const playerName = searchParams.get("name") || "Unknown Player";
    const platform = searchParams.get("platform") || "steam";

    const [accountId, setAccountId] = useState<string | null>(null);
    const [matches, setMatches] = useState<Match[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [cardViewType, setCardViewType] = useState<"compact" | "detailed">(
        "compact"
    );

    // Table Filters
    const [excludeTDM, setExcludeTDM] = useState<boolean>(false);
    const [excludeIBR, setExcludeIBR] = useState<boolean>(false);

    const excludedModes = [];
    if (excludeTDM) excludedModes.push("tdm");
    if (excludeIBR) excludedModes.push("ibr");

    // State for the selected game mode filter
    const [gameModeFilter, setGameModeFilter] = useState<string>("all");

    // Function to handle game mode change
    // Updated handleGameModeChange function
    const handleGameModeChange = (event: SelectChangeEvent<string>) => {
        setGameModeFilter(event.target.value);
    };

    // Filter the matches based on the selected game mode
    const filteredMatches = matches.filter((match) => {
        if (gameModeFilter === "all") return true; // Show all matches
        return match.gameMode
            .toLowerCase()
            .includes(gameModeFilter.toLowerCase());
    });

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/player-stats/matches",
            title: "Recent Matches Page",
        });
    }, []);

    const stats = calculateSummaryStats(filteredMatches, excludedModes);

    // Step 1: Fetch the player's account ID
    useEffect(() => {
        const fetchAccountId = async () => {
            setLoading(true);
            setError(null);

            const cacheKey = `account-${platform}-${playerName}`;
            const cachedAccountId = loadFromCache(cacheKey);

            if (cachedAccountId) {
                // Use cached account ID if available
                setAccountId(cachedAccountId);
                setLoading(false);
                return;
            }

            try {
                const accountResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
                );

                if (!accountResponse.ok) {
                    throw new Error(
                        `Failed to fetch player info for ${playerName}.`
                    );
                }

                const accountData = await accountResponse.json();
                setAccountId(accountData.accountId);

                // Cache the account ID for future use
                saveToCache(cacheKey, accountData.accountId);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching player account."
                );
            } finally {
                setLoading(false);
            }
        };

        if (playerName && platform) {
            fetchAccountId();
        }
    }, [platform, playerName]);

    // Step 2: Fetch matches after the accountId is set
    useEffect(() => {
        if (!accountId) return;

        const fetchMatches = async () => {
            const CACHE_EXPIRATION_MATCHES = 5 * 60 * 1000; // 5 minutes in milliseconds
            const cacheKey = `matches-${platform}-${playerName}`;
            const cachedData = loadFromCache(cacheKey);

            if (cachedData) {
                console.log("Loaded matches from cache");
                setMatches(cachedData);
                return; // Skip API call if cached data exists
            }

            setLoading(true);
            setError(null);

            try {
                const accountResponse = await fetch(
                    `${process.env.REACT_APP_API_URL}/api/account/${platform}/${playerName}`
                );

                if (!accountResponse.ok) {
                    throw new Error(
                        `Failed to fetch player info for ${playerName}.`
                    );
                }

                const accountData = await accountResponse.json();

                const matchPromises = accountData.matchIds.map(
                    async (id: string): Promise<Match> => {
                        const matchResponse = await fetch(
                            `${process.env.REACT_APP_API_URL}/api/match/${platform}/${id}`
                        );

                        if (!matchResponse.ok) {
                            throw new Error(
                                `Failed to fetch details for match ID: ${id}`
                            );
                        }

                        const matchData = await matchResponse.json();

                        const rostersWithStats = computeRosterStats(
                            matchData.rosters,
                            matchData.participants
                        );

                        // Find the participant that matches the current player
                        const playerParticipant = matchData.participants.find(
                            (p: Participant) =>
                                p.name === playerName ||
                                p.playerId === accountId
                        );

                        if (!playerParticipant) {
                            throw new Error(
                                `Player ${playerName} (or Account ID: ${accountId}) not found in match ${id}`
                            );
                        }

                        // Find the roster the player belongs to
                        const playerRoster = rostersWithStats.find((roster) =>
                            roster.participants.includes(playerParticipant.id)
                        );

                        if (!playerRoster) {
                            throw new Error(
                                `Roster not found for player ${playerName} in match ${id}`
                            );
                        }

                        return {
                            ...matchData,
                            playerWinPlace: playerParticipant.winPlace,
                            playerRosterKills: playerRoster.totalKills,
                            playerRosterDamage: playerRoster.totalDamage,
                            playerKills: playerParticipant.kills,
                            playerDamage: playerParticipant.damageDealt,
                            playerSurvivalTime: playerParticipant.timeSurvived,
                        };
                    }
                );

                const matchDetails: Match[] = await Promise.all(matchPromises);

                // Cache the match details
                saveToCache(cacheKey, matchDetails);
                setMatches(matchDetails);
            } catch (err) {
                setError(
                    (err as Error).message ||
                        "An error occurred while fetching matches."
                );
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, [accountId, platform, playerName]);

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return (
            <Typography color="error" variant="body1">
                {error}
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: "1200px", // Adjust the max width as needed
                margin: "0 auto", // Centers the content horizontally
                width: "100%", // Ensures it adapts to smaller screens
                position: "relative", // Allows the toggle to stay relative to the container
            }}
        >
            <Typography variant="h5" gutterBottom>
                Match History (Last 14 Days)
            </Typography>
            <Box>
                {!loading && matches.length > 0 ? (
                    <TableContainer
                        component={Paper}
                        sx={{ maxWidth: "800px", margin: "20px auto" }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Metric</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Per Match</TableCell>
                                    <TableCell>Per Day</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Total Matches</TableCell>
                                    <TableCell>{stats.totalMatches}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        {stats.averageMatchesPerDay}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Wins</TableCell>
                                    <TableCell>{stats.totalWins}</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>{stats.winsPerDay}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Survival Time</TableCell>
                                    <TableCell>
                                        {stats.totalSurvivalTimeInHours} h
                                    </TableCell>
                                    <TableCell>
                                        {
                                            stats.averagePerMatchSurvivalTimeInMinutes
                                        }{" "}
                                        min
                                    </TableCell>
                                    <TableCell>
                                        {stats.averageDailySurvivalTimeInHours}{" "}
                                        h
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Kills</TableCell>
                                    <TableCell>{stats.totalKills}</TableCell>
                                    <TableCell>{stats.killsPerMatch}</TableCell>
                                    <TableCell>{stats.killsPerDay}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Damage</TableCell>
                                    <TableCell>{stats.totalDamage}</TableCell>
                                    <TableCell>
                                        {stats.damagePerMatch}
                                    </TableCell>
                                    <TableCell>{stats.damagePerDay}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    !loading && (
                        <Typography
                            variant="body1"
                            color="textSecondary"
                            sx={{ textAlign: "center", marginTop: 2 }}
                        >
                            No match data available to calculate stats.
                        </Typography>
                    )
                )}
                {/* Exclusion Checkboxes */}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={excludeTDM}
                            onChange={(e) => setExcludeTDM(e.target.checked)}
                        />
                    }
                    label="Exclude Team Deathmatch Stats"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={excludeIBR}
                            onChange={(e) => setExcludeIBR(e.target.checked)}
                        />
                    }
                    label="Exclude Intense Battle Royale Stats"
                />
            </Box>

            {/* Floating Toggle Button */}
            <Box
                sx={{
                    display: "flex", // Arrange toggle and filter horizontally
                    justifyContent: "center", // Center the controls
                    alignItems: "center", // Align vertically
                    gap: 2, // Add spacing between the button and dropdown
                    marginBottom: "16px", // Space between controls and content
                }}
            >
                <Button
                    onClick={() =>
                        setCardViewType((prev) =>
                            prev === "compact" ? "detailed" : "compact"
                        )
                    }
                    variant="contained"
                    sx={{ backgroundColor: COLORS.green }}
                >
                    Toggle to{" "}
                    {cardViewType === "compact" ? "Detailed" : "Compact"} View
                </Button>
                {/* Game Mode Filter */}
                <FormControl
                    variant="outlined"
                    size="small"
                    sx={{
                        minWidth: 120,
                        "& .MuiOutlinedInput-root": {
                            backgroundColor: COLORS.blue,
                        },
                    }}
                >
                    <InputLabel
                        sx={{
                            backgroundColor: COLORS.blue, // Matches the dropdown background
                            padding: "0 4px", // Add padding for better readability
                            marginLeft: "-4px", // Slight adjustment to align with dropdown
                            borderRadius: "4px", // Rounded corners
                            color: "white", // Text color
                        }}
                    >
                        Game Mode
                    </InputLabel>
                    <Select
                        value={gameModeFilter}
                        onChange={handleGameModeChange}
                        label="Game Mode"
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="solo">Solo</MenuItem>
                        <MenuItem value="duo">Duo</MenuItem>
                        <MenuItem value="squad">Squad</MenuItem>
                        <MenuItem value="tdm">Team Deathmatch</MenuItem>
                        <MenuItem value="ibr">Intense Battle Royale</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Grid container spacing={2}>
                {filteredMatches.map((match) => (
                    <Grid item xs={12} md={6} lg={4} key={match.matchId}>
                        <MatchCard
                            match={match}
                            accountId={accountId}
                            viewType={cardViewType}
                            platform={platform}
                            playerName={playerName}
                            showViewDetailsButton={true}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default RecentMatches;

import { Resource } from "pages/Resources";

export const youTubers: Resource[] = [
    {
        name: "WackyJacky101",
        link: "https://www.youtube.com/user/WJacky101",
        description:
            "Learn PUBG tips, strategies, and in-depth game mechanics from WackyJacky101.",
    },
    {
        name: "chocoTaco",
        link: "https://www.youtube.com/user/chocoTaco",
        description: "Watch top-tier gameplay and guides from chocoTaco.",
    },
];

export const pubgReport: Resource[] = [
    {
        name: "",
        link: "https://pubg.report/",
        description:
            "Search for your kills or deaths getting captured by streamers.",
    },
];

export const matchReplays: Resource[] = [
    {
        name: "pubg.sh",
        link: "https://pubg.sh/",
        description: "2D match replays",
    },
    {
        name: "Chicken Dinner",
        link: "https://chickendinner.gg/",
        description: "2D match replays",
    },
    {
        name: "PUBG-Replay.com",
        link: "https://pubg-replay.com/",
        description: "2D match replays",
    },
];

export const esports: Resource[] = [
    {
        name: "PUBG Esports",
        link: "https://pubgesports.com/en",
        description:
            "Official PUBG Esports website for schedules, teams, and updates.",
    },
    {
        name: "Liquipedia PUBG",
        link: "https://liquipedia.net/pubg/Main_Page",
        description: "Comprehensive wiki for PUBG esports events and players.",
    },
];

export const generalWiki: Resource[] = [
    {
        name: "Weapons",
        link: "https://pubg.com/en/game-info/weapons/ar",
        description: "Official PUBG weapons coverage.",
    },
    {
        name: "Maps",
        link: "https://pubg.com/en/game-info/maps/erangel",
        description: "Official PUBG maps coverage.",
    },
    {
        name: "Vehicles",
        link: "https://pubg.com/en/game-info/vehicles/land",
        description: "Official PUBG vehicles coverage.",
    },
];
